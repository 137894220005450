export enum ErrorBlockStyle {
  General = "general",
  NoDashboards = "nodashboards",
}

export interface IErrorBlockProps {
  title: string;
  message: string;
  style?: ErrorBlockStyle;
}

const ErrorBlock = function (props: IErrorBlockProps) {
  return (
    <div className="c-page-message__wrapper">
      <div className="c-page-message">
        <p className="c-page-message__title">{props.title}</p>
        <p dangerouslySetInnerHTML={{ __html: props.message }}></p>
      </div>
    </div>
  );
};

export default ErrorBlock;
