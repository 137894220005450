import { ReactComponent as IconAdd } from "assets/svg/add.svg";
import UsersListing from "components/cards/users-listing";
import { useHistory, useLocation } from "react-router-dom";

export interface IUsersTabProps {
  clientId: string;
}

export default function UsersTab(props: IUsersTabProps) {
  let history = useHistory();
  let location = useLocation();
  let newUserLink = location.pathname + "/new";

  return (
    <div className="c-tabs-item is-active" id="tabsUsers">
      <div className="u-mb-spacer-base-large">
        <div className="c-page-title">
          <h6>Users</h6>
          <div
            onClick={() => {
              history.push(newUserLink);
            }}
            className="c-button c-button--stroke c-button--secondary c-button--icon u-hidden@md"
          >
            <IconAdd className="o-svg-icon" />
          </div>
          <div
            onClick={() => {
              history.push(newUserLink);
            }}
            className="c-button c-button--stroke c-button--secondary u-hidden u-block@md"
          >
            <IconAdd className="o-svg-icon" />
            <span>Add new user</span>
          </div>
        </div>
        <UsersListing showAllUsers={false} clientId={props.clientId} />
      </div>
    </div>
  );
}
