import classNames from "classnames";
import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  forwardRef,
} from "react";

export enum ButtonSizing {
  "small" = "--small",
  "high" = "--high",
  "large" = "--large",
}

export enum ButtonScope {
  "primary" = "--primary",
  "secondary" = "--secondary",
  "white" = "--white",
  "notification" = "--notification",
}

export interface CustomButtonProps {
  scope?: ButtonScope;
  sizing?: ButtonSizing;
  fullWidth?: boolean;
  outline?: boolean;
  disabled?: boolean;
}

export type ButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  CustomButtonProps;

function getCommonClassNames({
  scope,
  sizing,
  fullWidth,
  outline,
  disabled,
}: CustomButtonProps): string {
  return classNames([
    "c-button",
    {
      ["c-button" + scope]: scope,
      ["c-button" + sizing]: sizing,
      "c-button--stroke": outline,
      "c-button--full": fullWidth,
      "is-disabled": disabled,
    },
  ]);
}

export const Button: React.FC<ButtonProps> = ({
  children,
  scope = ButtonScope.primary,
  sizing,
  outline = false,
  className,
  disabled,
  fullWidth = false,
  ...buttonProps
}) => {
  return (
    <button
      {...buttonProps}
      disabled={disabled}
      className={classNames([
        getCommonClassNames({
          scope,
          sizing,
          fullWidth,
          outline,
          disabled,
        }),
        className,
      ])}
    >
      {children}
    </button>
  );
};

export type ButtonLinkProps = DetailedHTMLProps<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> &
  CustomButtonProps;

export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  (
    {
      children,
      scope = ButtonScope.primary,
      sizing,
      outline = false,
      className,
      disabled,
      fullWidth = false,
      onClick,
      ...anchorProps
    },
    ref
  ) => {
    return (
      <a
        ref={ref}
        {...anchorProps}
        onClick={(event) => {
          if (disabled) {
            event.preventDefault();
            return;
          }

          if (onClick) {
            onClick(event);
          }
        }}
        className={classNames(
          getCommonClassNames({
            scope,
            sizing,
            fullWidth,
            outline,
            disabled,
          }),
          className
        )}
      >
        {children}
      </a>
    );
  }
);

export default Button;
