import * as React from "react";

// Components
import Listing from "components/cards/listing";
import {
  listingContext,
  ListingSortOption,
  ListingType,
  SortDirection,
} from "components/cards/listing-context";
import UserCard from "components/cards/user";

import api from "api";
import useRequest from "api/use-request";
import queryString from "query-string";
import { Suspense, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

export interface IUsersListingProps {
  showAllUsers: boolean;
  clientId: string;
}

const UsersListing: React.FunctionComponent<IUsersListingProps> = ({ showAllUsers, clientId }) => {
  let location = useLocation();

  let searchArgs = queryString.parse(location.search, { parseNumbers: true });
  console.log(clientId);
  if (searchArgs.client_id === undefined) {
    searchArgs.client_id = clientId;
  }
  if (searchArgs.limit === undefined) {
    searchArgs.limit = showAllUsers ? 48 : 12;
  }

  let users = useRequest(api.users.getUsers(searchArgs));

  let [selection, setSelection] = useState<string[]>([]);

  console.log(searchArgs);

  let sort_options: Array<ListingSortOption> = [
    { key: "first_name", title: "First Name, Ascending", direction: SortDirection.Ascending },
    { key: "first_name", title: "First Name, Descending", direction: SortDirection.Descending },
    { key: "created_at", title: "Created At, Ascending", direction: SortDirection.Ascending },
    { key: "created_at", title: "Created At, Descending", direction: SortDirection.Descending },
    { key: "last_seen", title: "Last Seen, Ascending", direction: SortDirection.Ascending },
    { key: "last_seen", title: "Last Seen, Descending", direction: SortDirection.Descending },
    { key: "role", title: "Role, Ascending", direction: SortDirection.Ascending },
    { key: "role", title: "Role, Descending", direction: SortDirection.Descending },
  ];

  function deleteUsers(ids: string[]) {
    let updates: Promise<unknown>[] = [];
    ids.forEach((id) => {
      updates.push(api.users.deleteUser({ id }).fetch());
    });
    Promise.all(updates).finally(() => {
      users.mutate();
      setSelection([]);
    });
  }

  function toggleSelectUsers(ids: string[]) {
    ids.forEach((id) => {
      const index = selection.indexOf(id);
      if (index > -1) {
        selection.splice(index, 1);
      } else {
        selection.push(id);
      }
    });
    setSelection([...selection]);
  }

  function selectAll(shouldSelect: boolean) {
    if (users.data && users.data?.data.length > 0) {
      users.data?.data.forEach((user) => {
        const index = selection.indexOf(user.id);
        if (shouldSelect && index < 0) {
          selection.push(user.id);
        }
        if (!shouldSelect && index > -1) {
          selection.splice(index, 1);
        }
      });
      setSelection([...selection]);
    }
  }

  return (
    <>
      {users.data && users.data?.data.length < 1 && !searchArgs.q ? (
        <NoUsersPlaceholder />
      ) : (
        <>
          <listingContext.Provider value={{ ...users, selection, sort_options }}>
            <Suspense fallback={<div>Loading...</div>}>
              <Listing
                deleteHandler={deleteUsers}
                selectionHandler={toggleSelectUsers}
                selectAllHandler={selectAll}
                defaultListingType={showAllUsers ? ListingType.list : ListingType.grid}
                defaultLimit={showAllUsers ? 48 : 12}
                shouldShowSearch={true}
                settingsKey={showAllUsers ? "AllUsers" : "Users"}
              >
                {users.data?.data.map((user) => (
                  <UserCard
                    deleteHandler={deleteUsers}
                    selectHandler={toggleSelectUsers}
                    key={user.id}
                    user={user}
                  />
                ))}
              </Listing>
            </Suspense>
          </listingContext.Provider>
        </>
      )}
    </>
  );
};

function NoUsersPlaceholder() {
  let history = useHistory();
  let location = useLocation();
  let newUserLink = location.pathname + "/new";

  return (
    <div className="u-mb-spacer-base-large">
      <div className="c-add c-add--link u-flex-nowrap@md">
        <div className="c-add__body">
          <div className="c-add__title">
            <div
              onClick={() => {
                history.push(newUserLink);
              }}
              className="c-link-cta c-add__link"
            >
              <span>Add new user</span>
            </div>
          </div>
          <div className="c-add__desc">
            <p>You currently don't have any users added, click here to start.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UsersListing;
