import { UserDetails } from "components/cards/user-edit";
import { PageContext } from "components/layout/page-context";
import { useContext, useEffect } from "react";
export interface IProfilePageProps {
  returnTo: string;
}

export default function ProfilePage({ returnTo }: IProfilePageProps) {
  const page = useContext(PageContext);

  useEffect(() => {
    page.setTitle("Edit Profile");
    page.setBackLink(returnTo);
    page.setShowsClientSwitcher(false);
  });

  return (
    <section className="c-block c-block--spacing-b-small">
      <UserDetails isNew={false} nextLocation={""} onUpdate={() => {}} isProfile={true} />
    </section>
  );
}
