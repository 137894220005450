import * as React from "react";
import * as z from "zod";

// Components
import { CheckboxField } from "components/form/checkbox";
import { Form, FormNotification } from "components/form/form";
import { InputField, InputFieldType } from "components/form/input";
import { SubmitButton } from "components/form/submit-button";

// Types
import { ButtonScope } from "components/button";
import { FormFieldVariant } from "components/form/common";
import { LocationState } from "routes";

// Hooks
import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useStore } from "store";

// Icons
import api from "api";
import useRequest from "api/use-request";
import { Notification, NotificationTypes } from "components/notification";

// TODO: customize the copy depending for returning visitors/users
const LoginPage: React.FunctionComponent = () => {
  const store = useStore();
  let history = useHistory();
  let location = useLocation<LocationState>();
  const [hasLoginFailed, setHasLoginFailed] = useState(false);
  const [didLogin, setDidLogin] = useState(false);

  const user = useRequest(api.users.getCurrentUser(), didLogin || store.isLoggedIn);

  async function loginSubmit(data: { username: string; password: string }) {
    if (await store.login(data)) {
      setDidLogin(true);
    } else {
      setHasLoginFailed(true);
    }
  }

  useEffect(() => {
    if (didLogin) {
      window.loader(true);
    }
  }, [didLogin]);

  // Route the user to his homepage
  useEffect(() => {
    if (!user.data) return;
    window.loader(false);
  }, [user, history, location.state]);

  return (
    <div className="c-login__flow-wrapper">
      <div className="c-login__flow c-login__flow--visible">
        <div className="c-block__header">
          <h2>Welcome back!</h2>
        </div>
        {hasLoginFailed && (
          <Notification type={NotificationTypes.error}>
            <p>Incorrect username or password.</p>
          </Notification>
        )}
        <Form
          validationSchema={z.object({
            username: z
              .string()
              .nonempty({ message: "Email is incorrect" })
              .email({ message: "Email is incorrect" }),
            password: z.string().nonempty({ message: "Password is incorrect" }),
          })}
          initialValues={{ username: "", password: "" }}
          onSubmit={loginSubmit}
        >
          <FormNotification />
          <InputField
            type={InputFieldType.email}
            variant={FormFieldVariant.fill}
            name="username"
            autoComplete="username"
            placeholder="Your email"
          />
          <InputField
            type={InputFieldType.password}
            variant={FormFieldVariant.fill}
            name="password"
            autoComplete="current-password"
            placeholder="Password"
          />
          <CheckboxField
            variant={FormFieldVariant.fill}
            name="remember"
            options="Keep me logged in"
          />
          <div className="c-form__footer">
            <SubmitButton fullWidth scope={ButtonScope.primary}>
              <span>Log in</span>
            </SubmitButton>
          </div>
        </Form>
      </div>
      <div className="c-login__flow c-login__flow--secondary c-login__flow--visible">
        <div className="u-text-center">
          <p className="u-mb-spacer-base-small">
            <Link to="/password/forgot" className="c-link-cta-basic">
              <span>Forgot Password?</span>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
