/* eslint-disable */
import { DashboardContextProvider } from "components/layout/dashboard-context";
import Odometer from "components/widgets/odometer";
import React from "react";

interface IDashboardPageProps {
  root: boolean | undefined;
}

const DashboardPage: React.FunctionComponent<IDashboardPageProps> = (props) => {
  return (
    <div>
      <DashboardContextProvider isRoot={props.root}>
        <Odometer />
      </DashboardContextProvider>
    </div>
  );
};

export default DashboardPage;
