import * as React from "react";

import { DashboardDetails } from "components/cards/dashboard-edit";
import { Route, Switch } from "react-router-dom";

export interface IUserEditModalProps {}

const DashboardEditModal: React.FunctionComponent<IUserEditModalProps> = () => {
  function onUpdate() {}
  return (
    <Switch>
      <Route path={`/client/:clientId/dashboards/:dashboardId/edit`}>
        <DashboardDetails isNew={false} nextLocation={``} onUpdate={onUpdate} isProfile={false} />
      </Route>
      <Route path={`/client/:clientId/dashboards/new`}>
        <DashboardDetails isNew={true} nextLocation={``} onUpdate={onUpdate} isProfile={false} />
      </Route>
      <Route path={`/dashboards/:dashboardId/edit`}>
        <DashboardDetails
          isNew={false}
          nextLocation={`/dashboards`}
          onUpdate={onUpdate}
          isProfile={false}
        />
      </Route>
      <Route path={`/dashboards/new`}>
        <DashboardDetails
          isNew={true}
          nextLocation={`/dashboards`}
          onUpdate={onUpdate}
          isProfile={false}
        />
      </Route>
    </Switch>
  );
};

export default DashboardEditModal;
