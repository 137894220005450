import { D3Gauge } from "components/visualization/d3-gauge";
import * as React from "react";

export interface IGaugeProps {
  id: string;
  label: string;
  inset: number;
  percent: number;
  units: number;
  scalar: number;
}
function getWindowDimensions() {
  const { innerWidth, innerHeight } = window;
  let size = innerWidth / 4;
  if (size > 250) {
    size = 250;
  }
  if (innerHeight < 350) {
    size = size - 50;
  }
  return size;
}

export function Gauge({ id, inset, label, percent, units, scalar }: IGaugeProps) {
  const [size, setSize] = React.useState(getWindowDimensions());
  const [config, setConfig] = React.useState({
    size: size,
    inset: inset,
    label: label,
    percent: percent,
    units: units,
    scalar: scalar,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [gauge, setGauge] = React.useState(new D3Gauge(`#${id}`, config));

  const handleResize = () => {
    setSize(getWindowDimensions());
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  React.useEffect(() => {
    gauge.remove();
    gauge.config.size = size;
    gauge.render();
  }, [size]);

  React.useEffect(() => {
    setConfig({
      size: size,
      inset: inset,
      label: label,
      percent: percent,
      units: units,
      scalar: scalar,
    });
    gauge.update(units, percent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inset, label, percent, units, scalar]);

  return (
    <div className="gauge">
      <div id={id}></div>
    </div>
  );
}

export default Gauge;
