import api from "api";
import {
  DashboardFormObject,
  DashboardFormSchema,
  DashboardObject,
} from "api/endpoints/dashboards";
import useRequest from "api/use-request";
import { useEffect, useState } from "react";

import { FormFieldVariant } from "components/form/common";
import { Form, FormNotification } from "components/form/form";
import InputField, { InputFieldType } from "components/form/input";
import { SubmitButton } from "components/form/submit-button";

import DeleteConfirmationAlert, {
  DeleteConfirmationAlertType,
} from "components/form/delete-confirmation";
import _ from "lodash";
import * as quartzite from "quartzite";
import Skeleton from "react-loading-skeleton";
import { useHistory, useParams } from "react-router-dom";
import { debug } from "utils/debug";

import { ClientObject } from "api/endpoints/clients";
import { Multiselect } from "components/controls/multiselect-control";
import SelectField from "components/form/select-field";
import SlugField from "components/form/slug";
import { Field } from "formik";
import { store } from "store";
import timezones from "utils/timezones";

import { ReactComponent as IconClose } from 'assets/svg/close.svg';

function formatDate(input: string) {
  const date = new Date(input);
  return quartzite.dateString(date);
}

export interface IDashboardDetailsProps {
  isNew: boolean;
  nextLocation: string;
  onUpdate: () => void;
  isProfile: boolean;
}

function newDashboardObj(): DashboardFormObject {
  return _.clone({
    id: "",
    name: "",
    slug: "",
    access: "authenticated",
    password: "",
    data_source: "",
    frequency: "* * * * *",
    client_id: "",
    timezone: "America/New_York",
    working_hours: "",
  });
}

export function DashboardDetails({ isNew, nextLocation, onUpdate }: IDashboardDetailsProps) {
  let [initialValue, setInitialValue] = useState<DashboardFormObject>();
  let [isDeleting, setIsDeleting] = useState(false);
  let [editedDashboard, setEditedDashboard] = useState({} as DashboardObject);
  let allClients = useRequest(api.clients.getClients({ page: 1, limit: 1000 }));

  let validationSchema = DashboardFormSchema.omit({
    id: true,
    password: true,
    working_hours: true,
  }).nonstrict();

  let history = useHistory();
  let { clientId, dashboardId } = useParams() as {
    clientId: string | undefined;
    dashboardId: string | undefined;
  };

  const submitHandler = (values: any, formikHelpers: any) => {
    const payload: DashboardFormObject = {
      id: dashboardId ? dashboardId : "",
      name: values.name,
      slug: values.slug,
      access: values.access,
      password: values.password ?? undefined,
      data_source: values.data_source,
      frequency: values.frequency,
      client_id: values.client_id,
      timezone: values.timezone,
      working_hours: values.working_hours,
    };
    let update: Promise<DashboardObject>;
    if (isNew) {
      update = api.dashboards.newDashboard(payload).fetch();
    } else {
      update = api.dashboards.updateDashboard(payload, dashboardId ?? "").fetch();
    }

    return update.then((response) => {
      if (isNew) {
        store.notifications.presentNotification(
          `Dashboard ${response.name} was created.`,
          true,
          "success"
        );
      } else {
        store.notifications.presentNotification(
          `Dashboard ${response.name} was updated.`,
          true,
          "success"
        );
      }

      if (isNew) {
        api.dashboards.getDashboard({ id: response.id }).fetch();
      }
      onUpdate();

      if (nextLocation !== "") {
        history.replace(nextLocation);
      } else {
        history.goBack();
      }
    });
  };

  const loadDashboard = async (dashboardId: string) => {
    let dashboard = await api.dashboards.getDashboard({ id: dashboardId }).fetch();
    if (dashboard) {
      setEditedDashboard(dashboard);
      setInitialValue({
        password: undefined,
        ...dashboard,
      });
    }
  };

  const initForm = () => {
    if (!dashboardId) {
      const client_id = clientId ? clientId : "";
      setInitialValue({
        ...newDashboardObj(),
        client_id,
      });
    } else {
      loadDashboard(dashboardId);
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(initForm, []);

  useEffect(() => {
    document.body.classList.add("has-modal-open");
    return () => {
      document.body.classList.remove("has-modal-open");
    };
  });

  function deleteEditedDashboard() {
    if (dashboardId) {
      let update = api.dashboards.deleteDashboard({ id: dashboardId }).fetch();
      onUpdate();
      update
        .then((response) => {
          setIsDeleting(false);
          history.push(nextLocation);
        })
        .catch((error) => {
          setIsDeleting(false);
          debug(error);
        });
    }
  }

  function closeModal() {
    history.goBack();
    window.scrollTo(0, 0);
  }

  return (
    <>
      {initialValue ? (
        <div className="c-modal__root">
          <div className="c-modal__wrapper c-modal__wrapper--lg">
            <div className="c-modal c-modal--overflow">
              <div className="c-modal__header">
                <div className="c-block c-block--spacing-t-extra-small">
                  <div className="o-container-fluid">
                    <div className="o-row o-row--fluid c-block__row u-flex-row-reverse">
                      <div className="o-col">
                        <div
                          onClick={() => {
                            closeModal();
                          }}
                          className="c-link-cta-basic"
                        >
                          <span>Close</span>
                          <IconClose className="o-svg-icon" />
                        </div>
                      </div>
                      <div className="o-col u-flex-grow">
                        <p className="c-modal__headline">{isNew ? "Add" : "Edit"} dashboard</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="c-modal__main">
                <div className="c-block c-block--spacing-t-extra-small c-block--spacing-b-small">
                  <div className="o-container-fluid">
                    <Form
                      validationSchema={validationSchema}
                      initialValues={initialValue}
                      onSubmit={submitHandler}
                      enableReinitialize={true}
                    >
                      {({ values, errors, setFieldValue }) => (
                        <div className="o-row">
                          <div className="o-col-6@sm o-col-7@md o-col-8@lg">
                            <FormNotification />
                            <div className="o-row">
                              <div className="o-col-12@lg o-col-12@xl">
                                <div className="u-mb-spacer-base-large">
                                  <h6>About</h6>
                                  <div className="o-row o-row--small-gutters">
                                    <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                                      <InputField
                                        type={InputFieldType.text}
                                        name={`name`}
                                        placeholder="Name"
                                        label="Name"
                                        variant={FormFieldVariant.fill}
                                        autocomplete={false}
                                      />
                                    </div>
                                    <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                                      <SlugField
                                        updating={!!dashboardId}
                                        defaultSlug={values.name}
                                        type={InputFieldType.text}
                                        name={`slug`}
                                        placeholder="Url Slug"
                                        label="Url Slug"
                                        variant={FormFieldVariant.fill}
                                        autocomplete={false}
                                      />
                                    </div>
                                    {allClients.data ? (
                                      <div className="o-col-12">
                                        <label htmlFor={"clients"} className="c-form-label">
                                          Client
                                        </label>
                                        <Field
                                          name="client_id"
                                          id="client_id"
                                          component={Multiselect}
                                          isMulti={false}
                                          placeholder="Client"
                                          options={allClients.data.data.map(
                                            (client: ClientObject) => {
                                              return {
                                                value: client.id,
                                                label: client.name,
                                              };
                                            }
                                          )}
                                        />
                                        <br />
                                      </div>
                                    ) : (
                                      <div />
                                    )}

                                    <div className="o-col-12">
                                      <InputField
                                        type={InputFieldType.text}
                                        name={`data_source`}
                                        placeholder="http://s3.amazonaws.com/locus-view/all-locus-units.xml"
                                        label="XML Data Source"
                                        variant={FormFieldVariant.fill}
                                        autocomplete={false}
                                      />
                                    </div>

                                    <div className="o-col-12">
                                      <div className="c-form-element">
                                        <label htmlFor="Timezone" className="c-form-label">
                                          Timezone
                                        </label>
                                        <div className="c-form-element__field">
                                          <Field
                                            name="timezone"
                                            id="timezone"
                                            component={Multiselect}
                                            isMulti={false}
                                            placeholder="Timezone"
                                            label="Timezone"
                                            options={timezones.map((timezone: string) => {
                                              return {
                                                value: timezone,
                                                label: timezone,
                                              };
                                            })}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="o-col-12">
                                      <InputField
                                        type={InputFieldType.text}
                                        textarea={true}
                                        name={`working_hours`}
                                        placeholder="Help text to display when the dashboard is inactive."
                                        label="Inactive Help Text"
                                        variant={FormFieldVariant.fill}
                                        autocomplete={false}
                                      />
                                    </div>
                                    <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                                      <SelectField name={`access`} label="Access Scheme">
                                        <option value="authenticated">Authenticated</option>
                                        <option value="public">Public</option>
                                        <option value="password">Password</option>
                                      </SelectField>
                                    </div>
                                    {values.access === "password" && (
                                      <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                                        <InputField
                                          type={InputFieldType.text}
                                          name={`password`}
                                          placeholder="Password"
                                          label="Password"
                                          variant={FormFieldVariant.fill}
                                          autocomplete={false}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="o-col-6@sm o-col-5@md o-col-4@lg">
                            <div className="c-card c-card--bg-light">
                              <div className="c-card__body">
                                <div className="c-card__header">
                                  <h6>{isNew ? "Create" : "Update"}</h6>
                                  {!isNew && (
                                    <div className="c-card__desc">
                                      {editedDashboard && editedDashboard.updated_at ? (
                                        <p>
                                          This dashboard was last updated{" "}
                                          {formatDate(editedDashboard.updated_at)}.
                                        </p>
                                      ) : (
                                        <p></p>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="o-row o-row--fluid c-button-group">
                                  <div className="o-col">
                                    <SubmitButton>
                                      <span>{isNew ? "Create" : "Update"} dashboard</span>
                                    </SubmitButton>
                                  </div>
                                  {!isNew && (
                                    <div className="o-col c-button-group__inline">
                                      <div
                                        className="c-link-cta-basic"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          setIsDeleting(true);
                                        }}
                                      >
                                        <span>Delete Dashboard</span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <DeleteConfirmationAlert
                                onDelete={deleteEditedDashboard}
                                onCancel={() => {
                                  setIsDeleting(false);
                                }}
                                resource_label={editedDashboard.name}
                                show={isDeleting}
                                type={DeleteConfirmationAlertType.Card}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <DashboardEditModalSkeleton />
      )}
    </>
  );
}

export function DashboardEditModalSkeleton() {
  return (
    <div className="c-modal__root">
      <div className="c-modal__wrapper c-modal__wrapper--lg">
        <div className="c-modal c-modal--overflow">
          <div className="c-modal__header">
            <div className="c-block c-block--spacing-t-extra-small">
              <div className="o-container-fluid">
                <div className="o-row o-row--fluid c-block__row u-flex-row-reverse">
                  <div className="o-col">
                    <Skeleton width={250} />
                  </div>
                  <div className="o-col u-flex-grow">
                    <p className="c-modal__headline">
                      <Skeleton width={250} />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="c-modal__main">
            <div className="c-block c-block--spacing-t-extra-small c-block--spacing-b-small">
              <div className="o-container-fluid">
                <div className="o-row">
                  <div className="o-col-6@sm o-col-7@md o-col-8@lg">
                    <div className="o-row">
                      <div className="o-col-6@lg o-col-7@xl">
                        <div className="u-mb-spacer-base-large">
                          <h6>
                            <Skeleton width={250} />
                          </h6>
                          <div className="o-row o-row--small-gutters">
                            <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                              <Skeleton width={200} height={60} />
                            </div>
                            <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                              <Skeleton width={200} height={60} />
                            </div>
                            <div className="o-col-12">
                              <Skeleton width={420} height={60} />
                            </div>
                          </div>
                        </div>
                        <div className="u-mb-spacer-base-large">
                          <h6>
                            <Skeleton width={200} />
                          </h6>
                          <div className="o-row o-row--small-gutters">
                            <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                              <Skeleton width={200} height={50} />
                            </div>
                            <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                              <Skeleton width={200} height={50} />
                            </div>
                          </div>
                          <p className="c-note">
                            <Skeleton count={2} />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="o-col-6@sm o-col-5@md o-col-4@lg">
                    <div className="c-card c-card--bg-light">
                      <div className="c-card__body">
                        <div className="c-card__header">
                          <h6>
                            <Skeleton width={250} />
                          </h6>
                          <div className="c-card__desc">
                            <p>
                              <Skeleton count={2} />
                            </p>
                          </div>
                        </div>
                        <div className="o-row o-row--fluid c-button-group">
                          <div className="o-col">
                            <Skeleton width={250} height={50} />
                          </div>
                          <div className="o-col c-button-group__inline">
                            <Skeleton width={250} height={50} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
