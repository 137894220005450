import classNames from "classnames";
import ErrorBoundary from "components/error-boundary";
import { PageContextProvider } from "components/layout/page-context";
import PageLoader from "components/layout/page-loader";
import { createRef, ReactChild, Suspense } from "react";
import { isObject } from "utils/checks";

interface IMainLayoutProps {
  children: ReactChild | INamedChildrenSlots;
  kioskMode?: boolean;
}

interface INamedChildrenSlots {
  content: ReactChild;
  header: ReactChild;
  footer: ReactChild;
}

export default function DashboardLayout(props: IMainLayoutProps) {
  const { children } = props;
  const headerRef = createRef<HTMLElement>();
  const footerRef = createRef<HTMLElement>();

  if (!children) {
    throw new Error("Layouts need view components as children.");
  }

  // Multiple named slots used
  if (hasNamedSlots(children)) {
    const { content } = children;

    return (
      <PageContextProvider headerRef={headerRef} footerRef={footerRef}>
        <div className={classNames(["c-body"])}>
          <ErrorBoundary>{content ? content : null}</ErrorBoundary>
        </div>
      </PageContextProvider>
    );
  }

  // Single component
  return (
    <PageContextProvider headerRef={headerRef} footerRef={footerRef}>
      <div className={classNames(["c-body"])}>
        <ErrorBoundary>
          <Suspense fallback={<PageLoader />}>{children}</Suspense>
        </ErrorBoundary>
      </div>
    </PageContextProvider>
  );
}

const hasNamedSlots = (children: any): children is INamedChildrenSlots =>
  isObject(children) && "content" in children;
