import ErrorBlock, { ErrorBlockStyle } from "components/layout/error-block";
import React from "react";

interface IErrorBoundaryProps {}

interface IErrorBoundaryState {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  // TODO: Typescript issues with Store Context in class components
  // declare context: React.ContextType<typeof RootStoreContext>;

  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: Object) {
    console.log("Error caught in boundary", error, errorInfo);
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorBlock
          style={ErrorBlockStyle.General}
          title="Our apologies"
          message={
            '<p>The Locus Ticker encountered an error it could not recover from. For further assistance, please <a href="https://localhost/support/">contact your administrator</a>, or try going to the <a href="/">homepage</a>.</p>'
          }
        />
      );
    }
    return this.props.children;
  }
}
