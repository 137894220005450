import classNames from "classnames";
import useClickAway from "hooks/use-click-away";
import * as React from "react";
import { useRef, useState } from "react";

export type SelectBoxOption = { value: string; label: string };

interface ISelectBoxControlProps {
  icon?: boolean;
  title?: string;
  subtitle?: string;
  alignment?: "right" | "left";
  multiselect?: boolean;
  options?: SelectBoxOption[];
  selectedOptions?: string[];
  currentValue: string;
  onChange?: (selectedValue: string) => void;
  listClass?: string;
}

export const SelectBoxControl: React.FunctionComponent<ISelectBoxControlProps> = ({
  icon = false,
  title,
  subtitle,
  alignment = "right",
  multiselect = false,
  options = [],
  selectedOptions = [],
  currentValue,
  onChange = undefined,
  listClass,
}) => {
  let [isOpen, setIsOpen] = useState(false);
  if (selectedOptions.length === 0) {
    selectedOptions.push(currentValue);
  }
  let [selection, setSelection] = useState(selectedOptions);

  const clickOutTrigger = useRef<HTMLDivElement>(null);
  useClickAway(clickOutTrigger, () => setIsOpen(false));

  function setSelectionByIndex(index: number) {
    if (options[index]) {
      setSelection([options[index].value]);
    }
  }

  function optionLabelForValue(value: string): string {
    let label = "";
    options.forEach((option: SelectBoxOption) => {
      if (option.value === value) label = option.label;
    });
    return label;
  }

  function currentTitle(): string {
    if (selection.length > 0) {
      return optionLabelForValue(selection[0]);
    }
    return "";
  }

  function toggleSelectItem(value: string) {
    let newSelection: string[];
    if (multiselect) {
      newSelection = [...selection];
      const index = selection.indexOf(value);
      if (index > -1) {
        newSelection.splice(index, 1);
      } else {
        newSelection.push(value);
      }
    } else {
      if (selection.includes(value)) {
        newSelection = [];
      } else {
        newSelection = [value];
      }
    }
    setSelection(newSelection);
  }

  function isSelected(item: string) {
    return selection.includes(item);
  }

  return (
    <div className="c-actions__col" ref={clickOutTrigger}>
      {icon && <div className="c-actions__icon">{icon}</div>}
      <p className="c-caption">{title}</p>
      <div
        className={classNames([
          "c-dropdown-wrapper",
          { "has-dropdown-open": isOpen },
          { "has-selected-items": selection.length > 0 },
        ])}
      >
        <span className="c-dropdown-value" onClick={() => setIsOpen(!isOpen)}>
          {currentTitle()}
        </span>
        <span className={`c-dropdown c-dropdown--${alignment}`}>
          <span className="c-dropdown__header c-dropdown__header--sm">
            {subtitle && <span className="c-dropdown__title">{subtitle}</span>}
            <span className="c-dropdown__toggle">close</span>
          </span>
          {options.length > 0 && (
            <span className="c-dropdown__body">
              {!multiselect && (
                <ul className={`c-dropdown__list ${listClass ?? ""}`}>
                  {options.map((option: SelectBoxOption, index) => (
                    <li
                      key={option.value}
                      className={classNames({
                        "is-selected": isSelected(option.value),
                      })}
                    >
                      <span
                        onClick={() => {
                          toggleSelectItem(option.value);
                          if (onChange) {
                            onChange(option.value);
                          }
                          setIsOpen(false);
                        }}
                      >
                        {option.label}
                      </span>
                    </li>
                  ))}
                </ul>
              )}
            </span>
          )}
        </span>
      </div>
    </div>
  );
};

export default SelectBoxControl;
