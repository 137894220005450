import { ReactComponent as IconAlertInfo } from "assets/svg/alert-info.svg";
import { DashboardContext } from "components/layout/dashboard-context";
import DashboardFooter from "components/layout/dashboard-footer";
import React, { createRef } from "react";
import { Count } from "./count";
import Gauge from "./gauge";

export function Odometer() {
  let context = React.useContext(DashboardContext);
  const footerRef = createRef<HTMLElement>();
  return (
    <div className="o-container-fluid o-container--full o-container--ticker-dashboard">
      <div className="ticker-app">
        {context.status === "network-error" ? (
          <div className="c-load-page__desc">
            Service Unreachable: Please check the network connection. Retrying in 10 seconds...
          </div>
        ) : context.status === "loading" ? (
          <div className="c-load-page__desc">Loading...</div>
        ) : (
          <div>
            <div className="rates">
              <Gauge
                id="gauge-sec"
                inset={3}
                label="Units/Sec"
                percent={Math.round(context.rate.PercentageS ?? 0)}
                units={context.rate.RateS ?? 0}
                scalar={2}
              />
              <Gauge
                id="gauge-min"
                inset={4}
                label="Units/Min"
                percent={Math.round(context.rate.PercentageM ?? 0)}
                units={context.rate.RateM ?? 0}
                scalar={100}
              />

              <Gauge
                id="gauge-hour"
                inset={7}
                label="Units/Hr"
                percent={Math.round(context.rate.PercentageH ?? 0)}
                units={context.rate.RateH ?? 0}
                scalar={5500}
              />
            </div>
            <div className="units">
              <Count />
            </div>
            {context.paused && (
              <div className="paused">
                <div className="c-alert c-alert--small@xs c-alert--row@md c-alert--paused c-color--invert">
                  <div className="c-alert__col c-alert__icon">
                    <IconAlertInfo className="o-svg-icon" />
                  </div>
                  <div className="c-alert__col c-alert__body">
                    <p>
                      <strong>
                        {context.client?.name} {context.dashboard?.name}
                      </strong>{" "}
                      is <strong>inactive.</strong>
                    </p>
                  </div>
                </div>
                {context.dashboard?.working_hours && <pre>{context.dashboard?.working_hours}</pre>}
              </div>
            )}
          </div>
        )}
      </div>
      <DashboardFooter ref={footerRef} />
    </div>
  );
}

export default Odometer;
