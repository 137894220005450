import * as React from "react";
import * as z from "zod";

// Components
import { Form, FormNotification } from "components/form/form";
import { InputField, InputFieldType } from "components/form/input";
import { SubmitButton } from "components/form/submit-button";
import { Notification, NotificationTypes } from "components/notification";

// Types
import { ButtonScope } from "components/button";
import { FormFieldVariant } from "components/form/common";

interface IDashboardPasswordFormProps {
  submitHandler: (password: string) => void;
  error?: string;
}

const DashboardPasswordForm: React.FunctionComponent<IDashboardPasswordFormProps> = ({
  submitHandler,
  error,
}) => {
  function handleSubmit(data: { password: string }) {
    submitHandler(data.password);
  }

  return (
    <div className="c-block c-block--full-height c-block--spacing-t c-block--spacing-b">
      <div className="o-container-fluid o-container--xs u-justify-between">
        <div>
          <div className="c-site-logo c-site-logo--basic c-site-logo--sm u-mb-spacer-base"></div>
        </div>
        <div className="c-login__flow-wrapper">
          <div className=" c-login__flow--visible">
            <div className="c-block__header">
              <h2>Password protected</h2>
            </div>
            <p>Please enter a password to view this dashboard.</p>
            {error && (
              <Notification type={NotificationTypes.error}>
                <p>{error}</p>
              </Notification>
            )}
            <Form
              validationSchema={z.object({
                password: z.string().nonempty({ message: "Password is required" }),
              })}
              initialValues={{ password: "" }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                handleSubmit(values);
              }}
            >
              <FormNotification />
              <InputField
                type={InputFieldType.password}
                variant={FormFieldVariant.fill}
                name="password"
                autoComplete="password"
                placeholder="Password"
              />
              <div className="c-form__footer">
                <SubmitButton fullWidth scope={ButtonScope.primary}>
                  <span>Submit</span>
                </SubmitButton>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPasswordForm;
