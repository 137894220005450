import { Link } from "react-router-dom";

export interface INotFoundPageProps {}

export default function NotFoundPage(props: INotFoundPageProps) {
  return (
    <section className="c-block c-block--spacing-b">
      <div className="o-container-fluid o-container--md u-text-center">
        <div className="c-block__content">
          <h2>Looks like someone's lost…</h2>
          <p>
            We can't seem to find the page you're looking for. You can go back, or try going to the{" "}
            <Link to={"/"} className="c-link-underline">
              <span>homepage</span>
            </Link>
            .
          </p>
        </div>
      </div>
    </section>
  );
}
