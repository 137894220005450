import config from "config";
import Cookies from "js-cookie";
import { createContext, ReactElement, RefObject, useState } from "react";

export type IPageContext = {
  title: string;
  setTitle: (title?: string, documentTitle?: string) => void;
  logoURL?: string;
  setLogoURL: (logoURL: string | undefined) => void;
  logoDarkURL?: string;
  setLogoDarkURL: (logoDarkURL: string | undefined) => void;
  hasLoaded: boolean;
  setHasLoaded: (newValue: boolean) => void;
  isLoading: boolean;
  setIsLoading: (newValue: boolean) => void;
  backLink?: string;
  setBackLink: (link?: string | undefined) => void;
  pageSkeleton?: ReactElement;
  setPageSkeleton: (skeleton: ReactElement) => void;
  selectedClientId?: string;
  setSelectedClientId: (selectedClientId?: string) => void;
  showsClientSwitcher: boolean;
  setShowsClientSwitcher: (showsClientSwitcher: boolean) => void;
  showsDashboardSelector: boolean;
  setShowsDashboardSelector: (showsDashboardSelector: boolean) => void;
  usesDarkMode: boolean;
  setUsesDarkMode: (newValue: boolean) => void;
  isKioskMode: boolean;
  setIsKioskMode: (newValue: boolean) => void;
  headerRef: RefObject<HTMLElement>;
  footerRef: RefObject<HTMLElement>;
};

export const PageContext = createContext({} as IPageContext);

export type IPageContextProvider = {
  children: ReactElement;
  kioskMode?: boolean;
  headerRef: RefObject<HTMLElement>;
  footerRef: RefObject<HTMLElement>;
};

export const PageContextProvider = ({
  children,
  kioskMode,
  headerRef,
  footerRef,
}: IPageContextProvider) => {
  let [title, _setTitle] = useState<string>(config.APP_NAME);
  let [logoURL, setLogoURL] = useState<string>();
  let [logoDarkURL, setLogoDarkURL] = useState<string>();
  let [backLink, setBackLink] = useState<string>();
  let [hasLoaded, setHasLoaded] = useState<boolean>(false);
  let [isLoading, setIsLoading] = useState<boolean>(false);
  let [pageSkeleton, setPageSkeleton] = useState<ReactElement>();
  let [selectedClientId, setSelectedClientId] = useState<string>();
  let [showsClientSwitcher, setShowsClientSwitcher] = useState<boolean>(false);
  let [showsDashboardSelector, setShowsDashboardSelector] = useState<boolean>(false);
  let [usesDarkMode, setUsesDarkMode] = useState<boolean>(
    Cookies.get(config.DARKMODE_COOKIE_NAME) === "1"
  );
  let [isKioskMode, setIsKioskMode] = useState<boolean>(kioskMode ?? false);

  function setTitle(title?: string, documentTitle?: string) {
    if (title) {
      _setTitle(title);
    } else {
      _setTitle(config.APP_NAME);
    }

    if (documentTitle) {
      document.title = `${documentTitle} - ${config.APP_NAME}`;
    } else if (title) {
      document.title = `${title} - ${config.APP_NAME}`;
    } else {
      document.title = config.APP_NAME;
    }
  }

  const initialContext = {
    title,
    setTitle,
    logoURL,
    setLogoURL,
    logoDarkURL,
    setLogoDarkURL,
    backLink,
    setBackLink,
    isLoading,
    setIsLoading,
    hasLoaded,
    setHasLoaded,
    pageSkeleton,
    setPageSkeleton,
    selectedClientId,
    setSelectedClientId,
    showsClientSwitcher,
    setShowsClientSwitcher,
    usesDarkMode,
    setUsesDarkMode,
    showsDashboardSelector,
    setShowsDashboardSelector,
    isKioskMode,
    setIsKioskMode,
    headerRef,
    footerRef,
  };

  return <PageContext.Provider value={initialContext}>{children}</PageContext.Provider>;
};
