import * as React from "react";

import classNames from "classnames";

// Hooks
import { useContext, useRef, useState } from "react";

// Icons
import { ReactComponent as IconEye } from "assets/svg/eye.svg";
import { ReactComponent as IconMore } from "assets/svg/more.svg";
import { ReactComponent as IconPen } from "assets/svg/pen.svg";
import { ReactComponent as IconTrash } from "assets/svg/trash.svg";

import { DashboardObject } from "api/endpoints/dashboards";
import { Link, useLocation } from "react-router-dom";

import DeleteConfirmationAlert, {
  DeleteConfirmationAlertType,
} from "components/form/delete-confirmation";
import useClickAway from "hooks/use-click-away";
import { useStore } from "store";
import { getRelativeTimeString } from "utils/timezones";
import { listingContext } from "./listing-context";

interface IDashboardCardProps {
  dashboard: DashboardObject;
  deleteHandler: (ids: string[]) => void;
  selectHandler: (ids: string[]) => void;
}

const DashboardCard: React.FunctionComponent<IDashboardCardProps> = ({
  dashboard,
  deleteHandler,
  selectHandler,
}) => {
  const store = useStore();
  const [showActions, setShowActions] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { selection } = useContext(listingContext);

  const trigger = useRef<HTMLDivElement>(null);
  useClickAway(trigger, () => setShowActions(false));

  let location = useLocation();
  let editDashboardLink = location.pathname + `/${dashboard.id}/edit`;

  function isSelected(id: string) {
    if (selection && selection.indexOf(id) > -1) {
      return true;
    }
    return false;
  }

  return (
    <div className="c-listing__item">
      <div
        ref={trigger}
        className={classNames([
          "c-card",
          "c-card--link-main",
          {
            "c-card--bg-white": parseInt(dashboard.accuracy) < 60,
            "c-card--bg-yellow":
              parseInt(dashboard.accuracy) > 59 && parseInt(dashboard.accuracy) < 120,
            "c-card--bg-alert": parseInt(dashboard.accuracy) > 119,
          },

          { "is-selected": isSelected(dashboard.id) },
          { "has-popup-open": showActions },
        ])}
      >
        <div className="c-card__body">
          <div className="c-card__content">
            <p className="c-card__title">
              {store.isSuperAdmin ? (
                <Link to={`/${dashboard.client?.slug}/${dashboard.slug}`}>{dashboard.name}</Link>
              ) : (
                <Link to={`/${dashboard.client?.slug}/${dashboard.slug}`}>
                  {dashboard.client?.name} / {dashboard.name}
                </Link>
              )}
            </p>

            <div className="c-card__info">
              {store.isSuperAdmin && (
                <>
                  {parseInt(dashboard.accuracy) > 60 && dashboard.last_updated ? (
                    <p>
                      This dashboard hasn't been able to sync recently. The last successful sync was{" "}
                      <strong>{getRelativeTimeString(dashboard.last_updated)}</strong>
                    </p>
                  ) : dashboard.last_updated ? (
                    <p>
                      Last Fetch: <strong>{getRelativeTimeString(dashboard.last_updated)}</strong>
                    </p>
                  ) : (
                    ""
                  )}
                </>
              )}
              {dashboard.timezone ? (
                <p>
                  <strong>{dashboard.timezone}</strong>
                </p>
              ) : (
                ""
              )}
              {dashboard.is_paused ? <p>Warehouse is inactive</p> : ""}
            </div>
          </div>
          <div className="c-card__footer">
            <Link
              to={`/${dashboard.client?.slug}/${dashboard.slug}`}
              className="c-link-cta-light c-card__link-main"
            >
              <IconEye className="o-svg-icon" />
              <span>View</span>
            </Link>
            {store.isSuperAdmin && (
              <Link to={editDashboardLink} className="c-link-cta-light c-card__link-hidden">
                <IconPen className="o-svg-icon" />
                <span>Edit</span>
              </Link>
            )}

            {store.isSuperAdmin && (
              <>
                <div className="c-card__actions c-card__link-hidden">
                  <div
                    onClick={(event) => setShowActions(!showActions)}
                    className="c-card__actions-toggle"
                  >
                    <IconMore className="o-svg-icon" />
                  </div>
                  <div className="c-popup">
                    <div className="c-popup__header c-popup__header--sm">
                      <p className="c-popup__title">Actions</p>
                    </div>
                    <div className="c-popup__body">
                      <ul className="c-popup__list c-popup__list--small">
                        <li>
                          <Link to={`/${dashboard.client?.slug}/${dashboard.slug}`}>
                            <IconEye className="o-svg-icon" />
                            <span>View Dashboard</span>
                          </Link>
                        </li>
                        <li className="c-popup__divider c-popup__divider--top">
                          <Link to={editDashboardLink}>
                            <IconPen className="o-svg-icon" />
                            <span>Edit Dashboard</span>
                          </Link>
                        </li>
                        <li className="c-popup__divider c-popup__divider--top">
                          <div
                            className="c-popup__link"
                            onClick={(event) => {
                              event.preventDefault();
                              setIsDeleting(true);
                              setShowActions(false);
                            }}
                          >
                            <IconTrash className="o-svg-icon" />
                            <span>Delete Dashboard</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <DeleteConfirmationAlert
            onDelete={() => {
              deleteHandler([dashboard.id]);
              setIsDeleting(false);
            }}
            onCancel={() => {
              setIsDeleting(false);
            }}
            resource_label={dashboard.name}
            show={isDeleting}
            type={DeleteConfirmationAlertType.Card}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
