import HttpClient from "api/http-client";
import * as z from "zod";

export const TokenRequestSchema = z.object({
  client_id: z.string().nonempty(),
  client_secret: z.string().nonempty(),
  grant_type: z.literal("password"),
});

export const LoginTokenRequestSchema = z.union([
  TokenRequestSchema,
  z.object({
    username: z.string().email(),
    password: z.string().nonempty(),
  }),
]);

export const RefreshTokenRequestSchema = z.union([
  TokenRequestSchema,
  z.object({
    refresh_token: z.string().nonempty(),
  }),
]);

export const TokenResponseSchema = z.object({
  token_type: z.string().nonempty(),
  expires_in: z.number().int(),
  access_token: z.string().nonempty(),
  refresh_token: z.string().nonempty(),
});

export const PasswordResetRequestSchema = z.object({
  client_id: z.string().nonempty(),
  client_secret: z.string().nonempty(),
  grant_type: z.literal("reset_password_grant"),
  email: z.string().email(),
  token: z.string().nonempty(),
});

export type TokenRequest = z.infer<typeof TokenRequestSchema>;
export type LoginTokenRequest = z.infer<typeof LoginTokenRequestSchema>;
export type ConfirmPasswordTokenRequest = z.infer<typeof PasswordResetRequestSchema>;
export type RefreshTokenRequest = z.infer<typeof RefreshTokenRequestSchema>;
export type TokenResponse = z.infer<typeof TokenResponseSchema>;

export function getToken(data: LoginTokenRequest) {
  return new HttpClient().request<TokenResponse>({
    method: "post",
    url: "/oauth/token",
    validator: TokenResponseSchema,
    data,
  });
}

export function confirmResetPassword(data: ConfirmPasswordTokenRequest) {
  return new HttpClient().request<TokenResponse>({
    method: "post",
    url: "/oauth/token",
    validator: TokenResponseSchema,
    data,
  });
}

export function refreshToken(data: RefreshTokenRequest) {
  return new HttpClient().request<TokenResponse>({
    method: "post",
    url: "/oauth/token",
    validator: TokenResponseSchema,
    data,
  });
}
