import * as auth from "./endpoints/auth";
import * as users from "./endpoints/users";
import * as password from "./endpoints/password";
import * as service from "./endpoints/service";
import * as clients from "./endpoints/clients";
import * as dashboards from "./endpoints/dashboards";
import * as files from "./endpoints/files";

const api = {
  auth,
  users,
  password,
  service,
  clients,
  dashboards,
  files,
};

export default api;
