import * as React from 'react';
import { ReactChild, useContext, useState } from 'react';
import classNames from 'classnames';
import ListingHeader, { IListingHeaderCustomAction } from 'components/cards/listing-header';
import ListingFooter from 'components/cards/listing-footer';
import { listingContext, ListingType } from 'components/cards/listing-context';
import Skeleton from 'react-loading-skeleton';

interface IListingProps {
  children?: ReactChild | ReactChild[],
  selectionHandler?: (selection: any[]) => void,
  deleteHandler?: (items: any[]) => void,
  selectAllHandler?: (shouldSelectAll: boolean) => void,
  customActions?: IListingHeaderCustomAction[],
  defaultListingType?: ListingType,
  shouldShowSearch?: boolean,
  shouldHideListingTypes?: boolean,
  defaultLimit?: number,
  settingsKey?: string,
  compact?: boolean,
}

const Listing: React.FunctionComponent<IListingProps> = ({ children, selectionHandler, deleteHandler, selectAllHandler, customActions, defaultListingType, shouldShowSearch, shouldHideListingTypes, defaultLimit, settingsKey, compact }) => {
  let storageKey = (settingsKey ?? '') + 'SelectedListingType';
  let storedListingType = () => localStorage.getItem(storageKey);
  let setStoredListingType = (selectedType: ListingType) => localStorage.setItem(storageKey, selectedType);

  const [type, setType] = useState(storedListingType() as ListingType ?? (defaultListingType ?? ListingType.grid));
  const listing = useContext(listingContext); // TODO: should be refactored into the new context pattern eg. page/sisense context
  listing.type = type;


  return (
    <>
      <ListingHeader
        selectionHandler={selectionHandler}
        typeChangeHandler={(type: ListingType) => {
          setStoredListingType(type);
          setType(type);
        }}
        deleteHandler={deleteHandler}
        selectAllHandler={selectAllHandler}
        customActions={customActions}
        showSearch={shouldShowSearch ?? false}
        hideListingTypes={shouldHideListingTypes ?? false}
      />
      <div className={classNames([
        'c-listing',
        'o-stack-2@sm',
        { 'c-listing--grid': type === ListingType.grid },
        { 'o-stack-3@lg': type === ListingType.grid },
        { 'o-stack-4@xl': type === ListingType.grid },
        { 'c-listing--block@md': type === ListingType.list },
        { 'o-stack-1@md': type === ListingType.list },
        { 'c-listing--compact': compact },
      ])}>
        {listing.data?.data
          ? children
          : <CardSkeleton count={5} />
        }
      </div>
      <ListingFooter defaultLimit={defaultLimit} compact={compact} />
    </>
  );
};

export default Listing;

interface ICardSkeleton {
  count: number;
}

const CardSkeleton = ({ count }: ICardSkeleton) => {
  const cards = [];
  for (let index = 0; index < count; index++) {
    cards[index] = (
      <div className="c-listing__item c-listing__item--skeleton" key={'card_' + index}>
        <div className="c-card">
          <Skeleton />
        </div>
      </div>
    );
  }
  return <>{cards}</>;
}
