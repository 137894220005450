import * as d3 from "d3";

export interface IGaugeConfig {
  size: number;
  inset: number;
  label: string;
  percent: number;
  units: number;
  [key: string]: any;
}

export interface IGaugeState {
  el: string;
  $el: any;
}

export class D3Gauge {
  container: string;
  configuration: any;
  config: IGaugeConfig;
  svg: any;
  arc: any;
  foreground: any;
  currentVal: number;
  current: any;
  scalar: number;
  fillColor: string = "27aae1";
  accelerateColor: string = "27aae1";
  decelerateColor: string = "0076cc";
  transitioning: boolean = false;
  transitionValue: number = 0;
  private float_formatter: Intl.NumberFormat = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  private integer_formatter: Intl.NumberFormat = new Intl.NumberFormat("en-US");

  constructor(container: string, configuration: IGaugeConfig) {
    this.container = container;
    this.configuration = configuration;
    this.currentVal = configuration.units;
    this.transitionValue = configuration.units;
    this.scalar = configuration.scalar;
    this.config = {
      size: 300,
      inset: 15,
      label: "",
      percent: 0,
      units: 0,
    };
    this.configure(configuration);
  }

  // Degrees to Radians conversion
  private deg2rad = (deg: number) => (deg * Math.PI) / 180;

  // Update pointer value
  update = (newValue: number, percent: number) => {
    // Display Current value
    const parent = this;
    if (this.transitioning) {
      // We're currently in a transition.  Lets start a new transition from where we are
      this.currentVal = this.transitionValue;
    }
    this.transitioning = true;
    this.current
      .transition()
      .tween("text", function () {
        const start = parent.currentVal;
        var interpolator = d3.interpolateNumber(start, newValue); // d3 interpolator
        return function (t: number) {
          let count = interpolator(t);
          parent.transitionValue = count;
          let format = "";
          if (count > 99) {
            count = Math.round(count);
            format = parent.integer_formatter.format(count);
          } else {
            format = parent.float_formatter.format(count);
          }
          parent.current.text(format);
        }; // return value
      })
      .duration(45000)
      .on("end", () => {
        this.currentVal = newValue;
        // Signal the end of the transition
        this.transitioning = false;
      });

    // Arc Transition
    // Get the magnitude of the change
    const magnitude: number = percent - this.config.percent;
    let color = "#" + this.accelToColor(magnitude);
    if (this.currentVal !== newValue) {
      var numPi = this.deg2rad(Math.floor((percent * 270) / 100) - 135);
      this.foreground
        .transition()
        .duration(2000)
        .style("fill", color)
        .transition()
        .duration(42000)
        .call(this.arcTween, numPi);
    }
  };

  /**
   * Given a ratio, return a reasonable color indicating the intensity
   * @param ratio
   * @returns string
   */
  private accelToColor = (change: number) => {
    const color1 = change >= 0 ? this.accelerateColor : this.decelerateColor;
    const color2 = this.fillColor;
    // clamp the number no less
    change = Math.abs(change);
    const ratio = change / 100;

    if (ratio === 0) {
      return this.fillColor;
    }

    const hex = function (x: number) {
      const value: string = x.toString(16);
      return value.length === 1 ? "0" + value : value;
    };

    var r = Math.ceil(
      parseInt(color1.substring(0, 2), 16) * ratio +
        parseInt(color2.substring(0, 2), 16) * (1 - ratio)
    );
    var g = Math.ceil(
      parseInt(color1.substring(2, 4), 16) * ratio +
        parseInt(color2.substring(2, 4), 16) * (1 - ratio)
    );
    var b = Math.ceil(
      parseInt(color1.substring(4, 6), 16) * ratio +
        parseInt(color2.substring(4, 6), 16) * (1 - ratio)
    );
    return hex(r) + hex(g) + hex(b);
  };

  /**
   *
   * @param configuration
   */
  configure = (configuration: IGaugeConfig) => {
    // New configuration
    Object.keys(configuration).forEach((prop: string) => {
      this.config[prop] = configuration[prop];
    });
  };

  /**
   *
   * @param transition
   * @param newAngle
   */
  arcTween = (transition: any, newAngle: number) => {
    const arc = this.arc;
    transition.attrTween("d", function (d: any) {
      var interpolate = d3.interpolate(d.endAngle, newAngle);
      return function (t: number) {
        d.endAngle = interpolate(t);
        return arc(d);
      };
    });
  };
  /**
   * Remove the the element
   */
  remove = () => {
    d3.select(this.container + " svg").remove();
  };

  /**
   * Render gauge chart
   * @param newValue
   */
  render = (newValue?: number) => {
    var oR = this.config.size - this.config.size * 0.5;
    var iR = this.config.size - oR - this.config.size * 0.01 * this.config.inset;

    // Place svg element
    this.svg = d3
      .select(this.container)
      .append("svg")
      .attr("width", this.config.size)
      .attr("height", this.config.size)
      .append("g")
      .attr("transform", "translate(" + this.config.size / 2 + "," + this.config.size / 2 + ")");

    this.arc = d3
      .arc()
      .cornerRadius(20)
      .innerRadius(iR)
      .outerRadius(oR)
      .startAngle(this.deg2rad(-135));

    // Append background arc to svg
    this.svg
      .append("path")
      .datum({
        endAngle: this.deg2rad(135),
      })
      .style("fill", "RGBa(255,255,255,.2)")
      .attr("class", "gaugeBackground")
      // @ts-ignore
      .attr("d", this.arc);

    // Append foreground arc to svg
    this.foreground = this.svg
      .append("path")
      .datum({
        endAngle: this.deg2rad(Math.floor((this.config.percent * 270) / 100) - 135),
      })
      .style("fill", "#" + this.fillColor)
      // @ts-ignore
      .attr("d", this.arc);

    let count = this.config.units;
    let format = "";
    if (this.config.units > 99) {
      count = Math.round(this.config.units);
      format = this.integer_formatter.format(count);
    } else {
      format = this.float_formatter.format(count);
    }
    // Display Current value
    this.current = this.svg
      .append("text")
      .attr("text-anchor", "middle")
      .attr("class", "gauge-label")
      .style("fill", "#fff")
      .text(format);

    this.svg
      .append("text")
      .attr("text-anchor", "middle")
      .attr("class", "gauge-sublabel")
      .style("fill", "#bbb")
      .text(this.config.label);
  };
}
