import * as React from "react";
import * as z from "zod";

// Components
import { Form, FormNotification } from "components/form/form";
import { InputField, InputFieldType } from "components/form/input";
import { SubmitButton } from "components/form/submit-button";

// Types
import { ButtonScope } from "components/button";
import { FormFieldVariant } from "components/form/common";

// Hooks
import { Link } from "react-router-dom";

// API
import api from "api";
import { SendResetLinkRequest } from "api/endpoints/password";
import { useState } from "react";

const ForgotPasswordPage: React.FunctionComponent = () => {
  let [passwordResetLinkSent, setPasswordResetLinkSent] = useState(false);

  function submitHandler(data: { email: string }) {
    const payload: SendResetLinkRequest = {
      email: data.email,
      reset_url: window.location.origin + "/password/reset",
    };
    return api.password
      .sendResetLink(payload)
      .fetch()
      .then((response) => {
        setPasswordResetLinkSent(true);
      });
  }

  return (
    <>
      {passwordResetLinkSent ? (
        <div className="c-login__flow-wrapper">
          <div className="c-login__flow c-login__flow--visible">
            <div className="c-block__header">
              <h2>Your password reset link has been sent.</h2>
              <p>Please check your email and follow the instructions to reset your password.</p>
            </div>
          </div>
          <div className="c-login__flow c-login__flow--secondary c-login__flow--visible">
            <div className="u-text-center">
              <p className="u-mb-spacer-base-small">
                <Link to="/login" className="c-link-cta-basic">
                  <span>Back to login</span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="c-login__flow-wrapper">
          <div className="c-login__flow c-login__flow--visible">
            <div className="c-block__header">
              <h2>Don't worry.</h2>
              <p>Please enter your email used for login.</p>
            </div>
            <Form
              validationSchema={z.object({
                email: z.string().email(),
              })}
              initialValues={{ email: "" }}
              onSubmit={submitHandler}
            >
              <FormNotification />
              <InputField
                type={InputFieldType.email}
                variant={FormFieldVariant.fill}
                name="email"
                autoComplete="email"
                placeholder="Your email"
              />
              <div className="c-form__footer">
                <SubmitButton fullWidth scope={ButtonScope.primary}>
                  <span>Send</span>
                </SubmitButton>
              </div>
            </Form>
          </div>
          <div className="c-login__flow c-login__flow--secondary c-login__flow--visible">
            <div className="u-text-center">
              <p className="u-mb-spacer-base-small">
                <Link to="/login" className="c-link-cta-basic">
                  <span>Back to login</span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ForgotPasswordPage;
