import * as React from "react";
import * as z from "zod";

// Components
import { Form, FormNotification } from "components/form/form";
import { InputField, InputFieldType } from "components/form/input";
import { SubmitButton } from "components/form/submit-button";
import Skeleton from "react-loading-skeleton";
// Types
import { ButtonScope } from "components/button";
import { FormFieldVariant } from "components/form/common";

// Hooks
import { Link, useHistory, useLocation } from "react-router-dom";
import { useMount } from "react-use";

// API
import api from "api";
import { ResetPasswordRequest } from "api/endpoints/password";
import { useState } from "react";
import { useStore } from "store";

const VerifyEmailPage: React.FunctionComponent = () => {
  const location = useLocation();
  const store = useStore();
  let history = useHistory();
  let [loading, setLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [resetFormSubmitted, setResetFormSubmitted] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const email = searchParams.get("email");

  useMount(() => {
    if (!token || !email) {
      store.notifications.presentNotification(
        `The welcome link no longer works. Use the password reset to try to reset your password and contact support if you need help.`,
        true,
        "error"
      );
      history.replace("/login");
      return;
    }

    store
      .reset({ username: email, token })
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        store.notifications.presentNotification(error.message, true, "error");
        history.replace("/password/forgot");
      });
  });
  function submitHandler(data: { email: string; password: string; password_confirmation: string }) {
    const payload: ResetPasswordRequest = {
      email: email ?? "",
      password: data.password,
      password_confirmation: data.password_confirmation,
    };
    return api.password
      .resetPassword(payload)
      .fetch()
      .then((response) => {
        if (store.isSuperAdmin) {
          history.replace("/clients");
        } else {
          history.replace("/home");
        }
      });
  }

  return (
    <>
      {loading ? (
        <ResetFormSkeleton />
      ) : resetFormSubmitted ? (
        <div className="c-login__flow-wrapper">
          <div className="c-login__flow c-login__flow--visible">
            <div className="c-block__header">
              <h2>Your email has been verified and your password has been set.</h2>
              <p>Login below using the new password</p>
            </div>
          </div>
          <div className="c-login__flow c-login__flow--secondary c-login__flow--visible">
            <div className="u-text-center">
              <p className="u-mb-spacer-base-small">
                <Link to="/login" className="c-link-cta-basic">
                  <span>Login</span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="c-login__flow-wrapper">
          <div className="c-login__flow c-login__flow--visible">
            <div className="c-block__header">
              <h2>Welcome to Locus Ticker!</h2>
              <p>
                Create a strong password that contains at least: 8 characters, one number, one
                uppercase or lowercase letter, and one special character.
              </p>
            </div>
            <Form
              validationSchema={z.object({
                email: z.string().email().nonempty(),
                password: z.string().nonempty(),
                password_confirmation: z.string().nonempty(),
              })}
              initialValues={{ email: email ?? "", password: "", password_confirmation: "" }}
              onSubmit={submitHandler}
            >
              <FormNotification />
              {/* <InputField
                  type={InputFieldType.email}
                  variant={FormFieldVariant.fill}
                  name="email"
                  autoComplete="email"
                  placeholder="Your email"
                  hidden={true}
                /> */}
              <InputField
                type={InputFieldType.password}
                variant={FormFieldVariant.fill}
                name="password"
                autoComplete="password"
                placeholder="Your new password"
              />
              <InputField
                type={InputFieldType.password}
                variant={FormFieldVariant.fill}
                name="password_confirmation"
                autoComplete="password_confirmation"
                placeholder="Your new password confirmation"
              />

              <div className="c-form__footer">
                <SubmitButton fullWidth scope={ButtonScope.primary}>
                  <span>Save</span>
                </SubmitButton>
              </div>
            </Form>
          </div>
          <div className="c-login__flow c-login__flow--secondary c-login__flow--visible">
            <div className="u-text-center">
              <p className="u-mb-spacer-base-small">
                <Link to="/login" className="c-link-cta-basic">
                  <span>Back to login</span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export function ResetFormSkeleton() {
  return (
    <div className="c-login__flow-wrapper">
      <div className="c-login__flow c-login__flow--visible">
        <div className="c-block__header">
          <h2>
            <Skeleton width={250} />
          </h2>
          <p>
            <Skeleton height={50} />
          </p>
        </div>
      </div>

      <div className="o-col-12@md">
        <div className="c-card c-card--bg-light">
          <div className="c-card__body">
            <div className="c-card__header">
              <h6>
                <Skeleton width={250} />
              </h6>
              <div className="c-card__desc">
                <p>
                  <Skeleton count={3} />
                </p>
              </div>
            </div>
            <div className="o-row o-row--fluid c-button-group">
              <div className="o-col">
                <Skeleton height={50} />
              </div>
              <div className="o-col c-button-group__inline">
                <Skeleton height={50} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyEmailPage;
