import * as React from "react";
// Icons

// Components
import DashboardCard from "components/cards/dashboard";
import Listing from "components/cards/listing";
import {
  listingContext,
  ListingSortOption,
  ListingType,
  SortDirection,
} from "components/cards/listing-context";

import api from "api";
import useRequest from "api/use-request";
import { Can } from "authorizations";
import queryString from "query-string";
import { Suspense, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

export interface IDashboardsListingProps {
  showAllDashboards?: boolean;
  clientId?: string;
}

const DashboardsListing: React.FunctionComponent<IDashboardsListingProps> = ({
  showAllDashboards,
  clientId,
}) => {
  let location = useLocation();
  let searchArgs = queryString.parse(location.search, { parseNumbers: true });
  if (searchArgs.limit === undefined) {
    searchArgs.limit = showAllDashboards ? 48 : 12;
  }
  if (searchArgs.client_id === undefined && clientId) {
    searchArgs.client_id = clientId;
  }

  let [selection, setSelection] = useState<string[]>([]);

  let dashboards = useRequest(api.dashboards.getDashboards(searchArgs));

  let sort_options: Array<ListingSortOption> = [
    {
      key: "updated_at",
      title: "Updated At, Ascending",
      direction: SortDirection.Ascending,
    },
    {
      key: "updated_at",
      title: "Updated At, Descending",
      direction: SortDirection.Descending,
    },
    {
      key: "name",
      title: "Dashboard Name, Ascending",
      direction: SortDirection.Ascending,
    },
    {
      key: "name",
      title: "Dashboard Name, Descending",
      direction: SortDirection.Descending,
    },

    {
      key: "created_at",
      title: "Created At, Ascending",
      direction: SortDirection.Ascending,
    },
    {
      key: "created_at",
      title: "Created At, Descending",
      direction: SortDirection.Descending,
    },
  ];

  function deleteDashboards(ids: string[]) {
    let updates: Promise<unknown>[] = [];
    ids.forEach((id) => {
      updates.push(api.dashboards.deleteDashboard({ id }).fetch());
    });
    Promise.all(updates).finally(() => {
      dashboards.mutate();
      setSelection([]);
    });
  }

  function toggleSelectDashboards(ids: string[]) {
    ids.forEach((id) => {
      const index = selection.indexOf(id);
      if (index > -1) {
        selection.splice(index, 1);
      } else {
        selection.push(id);
      }
    });
    setSelection([...selection]);
  }

  return (
    <>
      {dashboards.data && dashboards.data?.data.length < 1 && !searchArgs.q ? (
        <NoDashboardsPlaceholder />
      ) : (
        <>
          <listingContext.Provider value={{ ...dashboards, selection, sort_options }}>
            <Suspense fallback={<div>Loading...</div>}>
              <Listing
                deleteHandler={undefined}
                selectionHandler={toggleSelectDashboards}
                selectAllHandler={undefined}
                defaultListingType={showAllDashboards ? ListingType.list : ListingType.grid}
                defaultLimit={showAllDashboards ? 48 : 12}
                shouldShowSearch={true}
                settingsKey={showAllDashboards ? "AllDashboards" : "Dashboards"}
              >
                {dashboards.data?.data.map((dashboard) => (
                  <DashboardCard
                    deleteHandler={deleteDashboards}
                    selectHandler={toggleSelectDashboards}
                    key={dashboard.id}
                    dashboard={dashboard}
                  />
                ))}
              </Listing>
            </Suspense>
          </listingContext.Provider>
        </>
      )}
    </>
  );
};

function NoDashboardsPlaceholder() {
  let history = useHistory();
  let location = useLocation();
  let newDashboardLink = location.pathname + "/new/general";

  return (
    <div className="u-mb-spacer-base-large">
      <div className="c-add c-add--link u-flex-nowrap@md">
        <Can I="create" a="Dashboard">
          <div className="c-add__body">
            <div className="c-add__title">
              <div
                onClick={() => {
                  history.push(newDashboardLink);
                }}
                className="c-link-cta c-add__link"
              >
                <span>Add new dashboard</span>
              </div>
            </div>
            <div className="c-add__desc">
              <p>You currently don't have any dashboards added, click here to start.</p>
            </div>
          </div>
        </Can>
        <Can not I="create" a="Dashboard">
          <div className="c-add__body">
            <div className="c-add__title">
              <div
                onClick={() => {
                  history.push(newDashboardLink);
                }}
                className="c-link-cta c-add__link"
              >
                <span>No dashboards available</span>
              </div>
            </div>
            <div className="c-add__desc">
              <p>Contact your representative to access your dashboards.</p>
            </div>
          </div>
        </Can>
      </div>
    </div>
  );
}

export default DashboardsListing;
