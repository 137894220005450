import { Instance, types } from "mobx-state-tree";

export const AppNotification = types.model({
  identifier: types.string,
  type: types.enumeration(["form", "success", "error", "warning", "info"]),
  message: types.string,
  autoDismiss: types.boolean,
  cta: types.maybe(types.string),
});

interface IAppNotification extends Instance<typeof AppNotification> {}

/**
 * Notification store
 */
export const Notifications = types
  .model({
    app: types.array(AppNotification),
  })
  .views((self) => ({
    get hasNotification() {
      return true;
    },
  }))
  .actions((self) => ({
    presentNotification: function (
      message: string,
      autoDismiss?: boolean,
      type: "success" | "error" | "warning" | "info" | "form" = "form"
    ) {
      let notification: IAppNotification = {
        identifier: Math.random().toString(16).substr(2, 8),
        type,
        message: message,
        autoDismiss: autoDismiss || false,
        cta: undefined,
      };
      self.app.push(notification);
    },
    dismissNotification: function (identifier: string) {
      let index = self.app.findIndex((notification) => notification.identifier === identifier);
      if (index > -1) {
        self.app.splice(index, 1);
      }
    },
  }));

export default Notifications;
