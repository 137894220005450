import { GetListingRequestSchema, LinksSchema, MetaSchema } from "api/endpoints/common";
import HttpClient from "api/http-client";
import { dateRegex } from "api/util";
import * as z from "zod";
import { ClientObjectSchema } from "../clients";

export enum DashboardLayoutTypes {
  Full = "full",
}

export enum DashboardAccessTypes {
  Authenticated = "authenticated",
  Public = "public",
  Password = "password",
}

export type DashboardLayout = { role: DashboardLayoutTypes; title: string; priority: number };

export const UserRoles: DashboardLayout[] = [
  { role: DashboardLayoutTypes.Full, title: "Full Page", priority: 100 },
];

export const DashboardObjectSchema = z.object({
  id: z.string(),
  client_id: z.string(),
  author_id: z.string(),
  root: z.boolean(),
  accuracy: z.string(),
  is_paused: z.boolean(),
  name: z.string().nonempty(),
  slug: z.string().nonempty(),
  access: z.string().nonempty(),
  data_source: z.string().nonempty().url(),
  data_strategy: z.string(),
  frequency: z.string().nonempty(),
  client: ClientObjectSchema.optional(),
  working_hours: z.string().optional(),
  local_min: z.string().optional(),
  local_max: z.string().optional(),
  timezone: z.string(),
  created_at: z.string().regex(dateRegex).optional(),
  updated_at: z.string().regex(dateRegex).optional(),
  next_due: z.string().optional(),
  last_updated: z.string().regex(dateRegex).optional(),
});

export const DashboardFormSchema = z.object({
  id: z.string(),
  name: z.string().nonempty(),
  slug: z.string().nonempty(),
  access: z.string().nonempty(),
  password: z.string().optional(),
  data_source: z.string().nonempty().url(),
  frequency: z.string().nonempty(),
  working_hours: z.string().optional(),
  timezone: z.string(),
  client_id: z.string(),
});

export const CreateDashboardResponseSchema = DashboardObjectSchema;

export const GetDashboardsResponseSchema = z.object({
  data: z.array(DashboardObjectSchema),
  links: LinksSchema,
  meta: MetaSchema,
});

export const GetDashboardsRequestSchema = GetListingRequestSchema;

export const DashboardObjectIdSchema = z.object({
  id: z.string(),
});

export type DashboardObject = z.infer<typeof DashboardObjectSchema>;
export type DashboardFormObject = z.infer<typeof DashboardFormSchema>;
export type GetDashboardsRequest = z.infer<typeof GetDashboardsRequestSchema>;
export type GetDashboardsResponse = z.infer<typeof GetDashboardsResponseSchema>;
export type DashboardObjectId = z.infer<typeof DashboardObjectIdSchema>;

export function getDashboards(data: GetDashboardsRequest) {
  return new HttpClient().request<GetDashboardsResponse>({
    method: "get",
    url: "/dashboards",
    validator: GetDashboardsResponseSchema,
    params: data,
  });
}

export function newDashboard(data: Omit<DashboardFormObject, "id">) {
  return new HttpClient().request<DashboardObject>({
    method: "post",
    url: "/dashboard",
    validator: DashboardObjectSchema,
    data,
  });
}

export function getDashboard(data: DashboardObjectId) {
  return new HttpClient().request<DashboardObject>({
    method: "get",
    url: `/dashboard/${data.id}`,
    validator: DashboardObjectSchema,
  });
}

export function deleteDashboard(data: DashboardObjectId) {
  return new HttpClient().request({
    method: "delete",
    url: `/dashboard/${data.id}`,
    data,
  });
}

export function updateDashboard(data: DashboardFormObject, dashboard_id: string) {
  return new HttpClient().request<DashboardObject>({
    method: "put",
    url: `/dashboard/${dashboard_id}`,
    data,
  });
}

export const TickerDataObjectSchema = z.object({
  RateId: z.string().nonempty(),
  RateS: z.number(),
  RateM: z.number(),
  RateH: z.number(),
  PercentageS: z.number(),
  PercentageM: z.number(),
  PercentageH: z.number(),
  CurrentValue: z.number(),
  LastValue: z.number().nullable(),
  CurrentValueTimestamp: z.string().regex(dateRegex).optional(),
  LastValueTimestamp: z.string().regex(dateRegex).optional(),
  client: ClientObjectSchema.optional(),
  dashboard: DashboardObjectSchema.optional(),
});

export type TickerDataObject = z.infer<typeof TickerDataObjectSchema>;
export function widgetData(clientId: string, dashboardId: string, password: string | null) {
  return new HttpClient().request<TickerDataObject>({
    method: "get",
    url: `/data/ticker/${clientId}/${dashboardId}`,
    validator: TickerDataObjectSchema,
    params: { password },
  });
}

export function rootData() {
  return new HttpClient().request<TickerDataObject>({
    method: "get",
    url: `/data/ticker/root`,
    validator: TickerDataObjectSchema,
  });
}
