import { Ability, AbilityBuilder, AbilityClass, CanParameters } from "@casl/ability";
import { createContextualCan } from "@casl/react";
import { ClientObject } from "api/endpoints/clients";
import { DashboardObject } from "api/endpoints/dashboards";
import { UserObject, UserRoleTypes, roleForUser } from "api/endpoints/users";
import * as React from "react";
import { PartialDeep } from "type-fest";

export type Actions = "create" | "read" | "update" | "delete" | "list" | "manage" | "logout";
export type Subjects =
  | (PartialDeep<UserObject> & { __caslSubjectType__: "User" })
  | "User"
  | (PartialDeep<ClientObject> & { __caslSubjectType__: "Client" })
  | "Client"
  | (PartialDeep<DashboardObject> & { __caslSubjectType__: "Dashboard" })
  | "Dashboard"
  | "all";

export type TAppAbility = Ability<[Actions, Subjects]>;
export type TAppAbilityCanParams = CanParameters<[Actions, Subjects]>;
const AppAbility = Ability as AbilityClass<TAppAbility>;

export const defineAbilityFor = (user?: UserObject) => {
  const { can: allow, cannot: forbid, build } = new AbilityBuilder(AppAbility);

  if (user) {
    const userPermissions = () => {
      // Can update its own profile
      allow("update", "User", { id: user.id });
    };

    const adminPermissions = () => {
      allow("manage", "all");
      // Cannot delete self
      forbid("delete", "User", { id: user.id });
    };

    if (roleForUser(user).role === UserRoleTypes.User) {
      userPermissions();
    } else if (roleForUser(user).role === UserRoleTypes.Admin) {
      adminPermissions();
    }
  }

  return build();
};

export const AuthorizationContext = React.createContext<TAppAbility>(
  new AbilityBuilder(AppAbility).build()
);
export const Can = createContextualCan<TAppAbility>(AuthorizationContext.Consumer);
