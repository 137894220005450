import * as React from 'react';

// icons
import classNames from 'classnames';
import { range } from 'lodash';

export type IPaginationProps = {
  totalPages: number,
  currentPage: number,
  pageNeighbours: number,
  alwaysShowFirstLast: boolean,
  alwaysShowPrevNext: boolean,
  onPageSelect: (page: number) => void,
}

export const Pagination: React.FunctionComponent<IPaginationProps> = (props: IPaginationProps) => {

  // calculate visible pages range
  let start = props.currentPage - props.pageNeighbours;
  let end = props.currentPage + props.pageNeighbours;

  let spillOffset = 0;
  if (start < 1) {
    spillOffset = 1 - start;
  } else if (end > props.totalPages) {
    spillOffset = props.totalPages - end;
  }

  // offset by spill and clip by (1, totalPages)
  let shownRange = range(
    Math.max(start + spillOffset, 1),
    Math.min(end + spillOffset, props.totalPages) + 1, // +1 because range() goes up to but doesn't include "end"
    1
  );

  const renderPage = (page: number) => (
    <li key={page} className={classNames([{ 'c-pagination__item--active': props.currentPage === page }])}>
      <div
        className="c-pagination__link"
        onClick={(event) => {
          props.onPageSelect(page)
        }}
      >
        <span>{page}</span>
      </div>
    </li>
  );

  const renderSpacer = () => (
    <li className="c-pagination__item--break"><span>...</span></li>
  );

  return (
    <ul className="c-pagination">

      { props.alwaysShowPrevNext && (
        <li key="prev" className="c-pagination__item--prev">
          <div
            className={`c-pagination__link ${props.currentPage === 1 ? "c-pagination__link--disabled" : ""}`}
            onClick={(event) => {
              if (props.currentPage > 1) {
                props.onPageSelect(props.currentPage - 1)
              }
            }}
          >
            <span>Previous</span>
          </div>
        </li>
      )}

      { props.alwaysShowFirstLast && !shownRange.includes(1) && (
        renderPage(1)
      )}

      { props.alwaysShowFirstLast &&
        !shownRange.includes(1) &&
        shownRange[0] > 2 && (
          renderSpacer()
        )}

      { shownRange.map(page => renderPage(page))}

      { props.alwaysShowFirstLast &&
        !shownRange.includes(props.totalPages) &&
        shownRange[shownRange.length - 1] < props.totalPages - 1 &&
        (
          renderSpacer()
        )}

      { props.alwaysShowFirstLast && !shownRange.includes(props.totalPages) && (
        renderPage(props.totalPages)
      )}

      { props.alwaysShowPrevNext && (
        <li key="next" className="c-pagination__item--next">
          <div
            className={`c-pagination__link ${props.currentPage === props.totalPages ? "c-pagination__link--disabled" : ""}`}
            onClick={(event) => {
              if (props.currentPage !== props.totalPages) {
                props.onPageSelect(props.currentPage + 1)
              }
            }}
          >
            <span>Next</span>
          </div>
        </li>
      )}

    </ul>
  );
};

export default Pagination;
