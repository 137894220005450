import Notification from "components/notification";
import { observer } from "mobx-react";
import { useStore } from "store";

export interface IAppNotificationsProps {}

export const AppNotifications = observer((props: IAppNotificationsProps) => {
  const store = useStore();

  return (
    <>
      {store.notifications.app.map((notification, index) => (
        <Notification
          key={index}
          type={notification.type}
          autoDismissible={notification.autoDismiss === false ? undefined : 8000}
          identifier={notification.identifier}
        >
          <p>
            <strong>{notification.message}</strong>
          </p>
        </Notification>
      ))}
    </>
  );
});
