import * as React from "react";

import { UserDetails } from "components/cards/user-edit";
import { Route, Switch, useParams } from "react-router-dom";

export interface IUserEditModalProps {}

const UserEditModal: React.FunctionComponent<IUserEditModalProps> = () => {
  let { clientId, userId } = useParams() as {
    clientId: string | undefined;
    userId: string | undefined;
  };

  function onUpdate() {}

  return (
    <Switch>
      <Route path={`/client/:clientId/users/:userId/edit`}>
        <UserDetails isNew={false} nextLocation={``} onUpdate={onUpdate} isProfile={false} />
      </Route>
      <Route path={`/client/:clientId/users/new`}>
        <UserDetails isNew={true} nextLocation={``} onUpdate={onUpdate} isProfile={false} />
      </Route>
      <Route path={`/users/:userId/edit`}>
        <UserDetails isNew={false} nextLocation={``} onUpdate={onUpdate} isProfile={false} />
      </Route>
      <Route path={`/users/new`}>
        <UserDetails isNew={true} nextLocation={``} onUpdate={onUpdate} isProfile={false} />
      </Route>
    </Switch>
  );
};

export default UserEditModal;
