import * as React from "react";
import * as z from "zod";

// Components
import { Form, FormNotification } from "components/form/form";
import { InputField, InputFieldType } from "components/form/input";
import { SubmitButton } from "components/form/submit-button";

// Types
import { ButtonScope } from "components/button";
import { FormFieldVariant } from "components/form/common";

// Hooks
import { Link, useHistory, useLocation } from "react-router-dom";
import { useMount } from "react-use";

// API
import api from "api";
import { ResetPasswordRequest } from "api/endpoints/password";
import { useState } from "react";
import { useStore } from "store";

const ResetPasswordPage: React.FunctionComponent = () => {
  const location = useLocation();
  const store = useStore();
  let history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [resetFormSubmitted, setResetFormSubmitted] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const email = searchParams.get("email");

  useMount(() => {
    if (!token || !email) {
      store.notifications.presentNotification(
        `That link no longer seems to work.  Try to use the password reset again and contact support if you need help.`,
        true,
        "error"
      );

      history.replace("/login");

      return;
    }

    store.reset({ username: email, token }).catch((error) => {
      store.notifications.presentNotification(error.message, true, "error");
      history.replace("/password/forgot");
    });
  });

  const submitHandler = (data: {
    email: string;
    password: string;
    password_confirmation: string;
  }) => {
    const payload: ResetPasswordRequest = {
      email: data.email,
      password: data.password,
      password_confirmation: data.password_confirmation,
    };
    return api.password
      .resetPassword(payload)
      .fetch()
      .then((response) => {
        if (store.isSuperAdmin) {
          history.replace("/clients");
        } else {
          history.replace("/home");
        }
      });
  };

  return (
    <>
      {resetFormSubmitted ? (
        <div className="c-login__flow-wrapper">
          <div className="c-login__flow c-login__flow--visible">
            <div className="c-block__header">
              <h2>Your password has been updated.</h2>
              <p>Login using new password below.</p>
            </div>
          </div>
          <div className="c-login__flow c-login__flow--secondary c-login__flow--visible">
            <div className="u-text-center">
              <p className="u-mb-spacer-base-small">
                <Link to="/login" className="c-link-cta-basic">
                  <span>Login</span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="c-login__flow-wrapper">
          <div className="c-login__flow c-login__flow--visible">
            <div className="c-block__header">
              <h2>Reset your password.</h2>
              <p>
                Your password must be at least 8 characters long, contain at least one number, one
                uppercase or lowercase letter and at least one special character.
              </p>
            </div>
            <Form
              validationSchema={z
                .object({
                  email: z.string().email().nonempty(),
                  password: z
                    .string()
                    .nonempty()
                    .refine(
                      (value) =>
                        /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@()$%^&*=_{}[\]:;"'|\\<>,./~`±§+-]).{8,100}$/.test(
                          value
                        ),
                      "Your password needs to be 8 characters long, contain at least one number, one uppercase or lowercase letter and at least one special character (#?!@()$%^&*=_{}[]:;)."
                    ),
                  password_confirmation: z.string().nonempty(),
                })
                .refine((data) => data.password === data.password_confirmation, {
                  message: "The passwords do not match",
                  path: ["password_confirmation"],
                })}
              initialValues={{ email: email ?? "", password: "", password_confirmation: "" }}
              onSubmit={submitHandler}
            >
              <FormNotification />
              <InputField
                type={InputFieldType.email}
                variant={FormFieldVariant.fill}
                name="email"
                autoComplete="email"
                placeholder="Your email"
              />
              <InputField
                type={InputFieldType.password}
                variant={FormFieldVariant.fill}
                name="password"
                autoComplete="password"
                placeholder="Your new password"
              />
              <InputField
                type={InputFieldType.password}
                variant={FormFieldVariant.fill}
                name="password_confirmation"
                autoComplete="password_confirmation"
                placeholder="Your new password confirmation"
              />

              <div className="c-form__footer">
                <SubmitButton fullWidth scope={ButtonScope.primary}>
                  <span>Save</span>
                </SubmitButton>
              </div>
            </Form>
          </div>
          <div className="c-login__flow c-login__flow--secondary c-login__flow--visible">
            <div className="u-text-center">
              <p className="u-mb-spacer-base-small">
                <Link to="/login" className="c-link-cta-basic">
                  <span>Back to login</span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ResetPasswordPage;
