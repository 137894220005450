import api from "api";
import useRequest from "api/use-request";
import GuardedRoute from "components/routes/guarded";
import { observer } from "mobx-react";
import { RouteProps } from "react-router";
import { Redirect } from "react-router-dom";
import { LocationState } from "routes";
import { useStore } from "store";

export interface AuthRouteProps extends RouteProps {
  location?: RouteLocation;
}

interface RouteLocation extends Location {
  state: LocationState;
}

/**
 * Auth route redirects authenticated users to their main app screens
 * @param param0
 * @returns
 */
export const AuthRoute = observer((props: AuthRouteProps) => {
  const store = useStore();
  const user = useRequest(api.users.getCurrentUser(), store.isLoggedIn);

  return (
    <GuardedRoute
      {...props}
      guard={(params, children) => {
        if (store.isLoggedIn && store.userRole) {
          let destination = "/home";

          window.loader(false);
          if (
            props.location?.state &&
            props.location.state.from &&
            props.location.state.from.pathname
          ) {
            destination = props.location.state.from.pathname;
          } else if (store.isSuperAdmin) {
            destination = "/clients";
          }

          return <Redirect to={destination} />;
        } else {
          return children();
        }
      }}
    />
  );
});

export default AuthRoute;
