import HttpClient from "api/http-client";
import * as z from "zod";
import { GetListingRequestSchema, LinksSchema, MetaSchema } from "api/endpoints/common";
import { dateRegex } from "api/util";

export const ClientObjectSchema = z.object({
  id: z.string(),
  name: z.string().nonempty(),
  slug: z.string().nonempty(),
  description: z.string().nonempty(),
  website: z.string().nonempty(),
  created_at: z.string().regex(dateRegex).optional(),
  updated_at: z.string().regex(dateRegex).optional(),
  logo_url: z.string().optional(),
  logo_dark_url: z.string().optional(),
  thumbnail_url: z.string().optional(),
  thumbnail_dark_url: z.string().optional(),
});

export const ClientFormSchema = z.object({
  id: z.string(),
  name: z.string().nonempty(),
  slug: z.string().nonempty(),
  description: z.string().nonempty(),
  website: z.string().url(),
  logo: z.string(),
  logo_dark: z.string(),
  logo_url: z.string().optional(),
  logo_dark_url: z.string().optional(),
});

export const CreateClientResponseSchema = ClientObjectSchema;

export const GetClientsResponseSchema = z.object({
  data: z.array(ClientObjectSchema),
  links: LinksSchema,
  meta: MetaSchema,
});

export const GetClientsRequestSchema = GetListingRequestSchema;

export const ClientObjectIdSchema = z.object({
  id: z.string(),
});

export type ClientObject = z.infer<typeof ClientObjectSchema>;
export type ClientFormObject = z.infer<typeof ClientFormSchema>;
export type GetClientsRequest = z.infer<typeof GetClientsRequestSchema>;
export type GetClientsResponse = z.infer<typeof GetClientsResponseSchema>;
export type ClientObjectId = z.infer<typeof ClientObjectIdSchema>;

export function getClients(data: GetClientsRequest) {
  return new HttpClient().request<GetClientsResponse>({
    method: "get",
    url: "/clients",
    validator: GetClientsResponseSchema,
    params: data,
  });
}

export function newClient(data: Omit<ClientFormObject, "id">) {
  return new HttpClient().request<ClientObject>({
    method: "post",
    url: "/client",
    validator: ClientObjectSchema,
    data,
  });
}

export function getClient(data: ClientObjectId) {
  return new HttpClient().request<ClientObject>({
    method: "get",
    url: `/client/${data.id}`,
    validator: ClientObjectSchema,
  });
}

export function deleteClient(data: ClientObjectId) {
  return new HttpClient().request({
    method: "delete",
    url: `/client/${data.id}`,
    data,
  });
}

export function updateClient(data: ClientFormObject) {
  return new HttpClient().request<ClientObject>({
    method: "put",
    url: `/client/${data.id}`,
    data,
  });
}
