import api from "api";
import useRequest from "api/use-request";
import { PageContext } from "components/layout/page-context";
import { TabsNav, TabsPanel } from "components/layout/tabs";
import ClientGeneralTab from "components/tabs/client-general";
import DashboardsTab from "components/tabs/dashboards";
import UsersTab from "components/tabs/users";
import { useContext, useEffect } from "react";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";

export interface IClientPageProps {
  createNew?: boolean;
}

export interface IClientUrlParams {
  id: string;
}

export default function ClientPage({ createNew = false }: IClientPageProps) {
  const page = useContext(PageContext);

  useEffect(() => {
    page.setTitle(createNew ? "Add Client" : "Edit Client");
    page.setBackLink("/clients");
    page.setShowsClientSwitcher(false);
  });

  let match = useRouteMatch();

  let params = useParams() as {
    clientId: string | undefined;
  };
  let clientId = params.clientId ?? "";

  let client = useRequest(api.clients.getClient({ id: clientId }));

  const tabs = [
    { label: "About", path: `${match.url}` },
    { label: "Dashboards", path: `${match.url}/dashboards` },
    { label: "Users", path: `${match.url}/users` },
  ];

  return (
    <section className="c-block c-block--spacing-b-small">
      <div className="o-container-fluid o-container--full">
        <div className="c-page-title">
          <h2 className="c-title--large">{client.data?.name}</h2>
        </div>

        <TabsNav tabs={tabs}>
          <Switch>
            <Route path={tabs[0].path} exact>
              <TabsPanel>
                <ClientGeneralTab clientId={clientId} isNew={createNew} />
              </TabsPanel>
            </Route>
            <Route path={tabs[1].path}>
              <TabsPanel>
                <DashboardsTab clientId={clientId} />
              </TabsPanel>
            </Route>
            <Route path={tabs[2].path}>
              <TabsPanel>
                <UsersTab clientId={clientId} />
              </TabsPanel>
            </Route>
          </Switch>
        </TabsNav>
      </div>
    </section>
  );
}
