import classNames from "classnames";
import * as React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { useStore } from "store";
import { DashboardContext } from "./dashboard-context";

interface IFooterProps {
  ref: React.Ref<HTMLElement>;
}

export const renderTimeInZone = (timezone: string) => {
  let options = {
    timeZone: timezone,
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timezoneName: "short",
  } as const;
  const formatter = new Intl.DateTimeFormat([], options);
  return `${formatter.format(new Date())} ${timezone}`;
};

const DashboardFooter: React.ForwardRefExoticComponent<IFooterProps> = React.forwardRef(
  (props, ref) => {
    const dashboard = useContext(DashboardContext);
    const store = useStore();
    let [time, setTime] = React.useState(renderTimeInZone(dashboard.dashboard?.timezone || "UTC"));
    React.useEffect(() => {
      const interval = setInterval(() => {
        if (dashboard.dashboard?.timezone) {
          setTime(renderTimeInZone(dashboard.dashboard?.timezone || "UTC"));
        }
      }, 1000);
      return () => clearInterval(interval);
    }, [dashboard.dashboard?.timezone]);
    return (
      <footer
        id="site-footer"
        className={classNames(["c-site-footer", "c-color--invert", "u-bg-dash-dark"])}
        ref={ref}
      >
        <div className="o-container-fluid o-container--full">
          <div className="c-site-footer__wrapper">
            {dashboard.client?.logo_url && (
              <img src={dashboard.client?.logo_url} alt={`${dashboard.client?.name} Logo`} />
            )}

            <div className="c-site-footer__info">
              <p>
                {store.isLoggedIn ? (
                  <Link
                    to={
                      store.isSuperAdmin
                        ? `/client/${dashboard.client?.id}/dashboards?direction=asc&q=&sort=name`
                        : "/home"
                    }
                  >
                    {dashboard.client?.name && dashboard.client?.name} /{" "}
                    {dashboard.dashboard?.name && dashboard.dashboard?.name}
                  </Link>
                ) : (
                  <>
                    {dashboard.client?.name && dashboard.client?.name} /{" "}
                    {dashboard.dashboard?.name && dashboard.dashboard?.name}
                  </>
                )}
              </p>
              <p>
                {time}
                {!store.isLoggedIn && (
                  <>
                    &nbsp;| <Link to={`/login`}>Login</Link>
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
);

export default DashboardFooter;
