import { FieldConfig, Field as FormikField, useFormikContext } from "formik";
import * as React from "react";

import { ReactComponent as IconPen } from "assets/svg/pen.svg";
import classNames from "classnames";
import { FormFieldSizing, FormFieldVariant } from "components/form/common";
import { slugify } from "utils/string";
import ErrorMessage from "./error-message";

export enum InputFieldType {
  "text" = "text",
  "email" = "email",
  "password" = "password",
}

type SlugFieldProps<T> = JSX.IntrinsicElements["input"] &
  Omit<FieldConfig<T>, "component" | "as" | "render" | "children" | "type"> & {
    name: string;
  } & {
    defaultSlug: string;
    type: InputFieldType;
    variant?: FormFieldVariant;
    sizing?: FormFieldSizing;
    icon?: React.ReactNode | [React.ReactNode, React.ReactNode];
    label?: string;
    validateOnInit?: boolean;
    placeholder: string;
    textarea?: boolean;
    help?: string;
    elementClassName?: string;
    autocomplete?: boolean;
    updating?: boolean;
  };

export const SlugField = <T extends any>({
  defaultSlug,
  label,
  icon,
  name,
  validateOnInit,
  type = InputFieldType.text,
  variant,
  required = false,
  autocomplete = false,
  disabled,
  elementClassName,
  textarea,
  sizing,
  help,
  updating,
  ...formikFieldProps
}: SlugFieldProps<T>): React.ReactElement => {
  const { isSubmitting, getFieldMeta, setFieldValue, getFieldProps } = useFormikContext<any>();
  const [custom, setCustom] = React.useState<boolean>(false);
  const [slug, setSlug] = React.useState(defaultSlug);
  const fieldMeta = getFieldMeta(name);
  const errorMsg = fieldMeta.error;
  const hasError = typeof errorMsg !== "undefined" && fieldMeta.touched;

  React.useEffect(() => {
    if (slugify(defaultSlug) !== fieldMeta.value || updating) {
      setCustom(true);
    }
  }, []);
  React.useEffect(() => {
    if (defaultSlug && !custom && !updating) {
      setSlug(slugify(defaultSlug));
      setFieldValue(name, slug);
    }
  }, [defaultSlug, custom]);
  return (
    <div
      className={classNames([
        "c-form-element",
        "c-form-element--slug",
        {
          ["c-form-element" + sizing]: sizing,
          ["c-form-element" + variant]: variant,
          "c-form-element--error": hasError,
        },
      ])}
    >
      {label && (
        <label htmlFor={name} className={classNames("c-form-label")}>
          {label}
        </label>
      )}
      {!custom ? (
        <div
          className="c-form-element__display"
          onClick={(e) => {
            setFieldValue(name, slug);
            return setCustom(true);
          }}
        >
          <div>{slug}</div>
          <div className="c-link-cta-light">
            <IconPen className="o-svg-icon" />
          </div>
        </div>
      ) : (
        <div className="c-form-element__field">
          <FormikField
            name={name}
            type={type}
            value={custom ? fieldMeta.value : slug}
            as={textarea ? "textarea" : undefined}
            disabled={isSubmitting ? true : disabled}
            autoComplete={autocomplete ? "on" : "off"}
            {...formikFieldProps}
          />
        </div>
      )}
      <ErrorMessage name={name} />
      {help && <p className="c-note">{help}</p>}
    </div>
  );
};

export default SlugField;
