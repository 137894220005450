import * as React from "react";

import classNames from "classnames";

// Hooks
import { useContext, useRef, useState } from "react";

// Icons
import { ReactComponent as IconMore } from "assets/svg/more.svg";
import { ReactComponent as IconPen } from "assets/svg/pen.svg";
import { ReactComponent as IconTrash } from "assets/svg/trash.svg";

import { ClientObject } from "api/endpoints/clients";
import { Link } from "react-router-dom";

import DeleteConfirmationAlert, {
  DeleteConfirmationAlertType,
} from "components/form/delete-confirmation";
import useClickAway from "hooks/use-click-away";
import { getRelativeTimeString } from "utils/timezones";
import { listingContext } from "./listing-context";

interface IClientCardProps {
  client: ClientObject;
  deleteHandler: (ids: string[]) => void;
  selectHandler: (ids: string[]) => void;
  shouldShowClient: boolean;
}

const ClientCard: React.FunctionComponent<IClientCardProps> = ({
  client,
  deleteHandler,
  selectHandler,
  shouldShowClient,
}) => {
  const [showActions, setShowActions] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { selection } = useContext(listingContext);

  const trigger = useRef<HTMLDivElement>(null);
  useClickAway(trigger, () => setShowActions(false));
  let editClientLink = `/client/${client.id}`;

  function isSelected(id: string) {
    if (selection && selection.indexOf(id) > -1) {
      return true;
    }
    return false;
  }

  return (
    <div className="c-listing__item">
      <div
        ref={trigger}
        className={classNames([
          "c-card",
          "c-card--link",
          "c-card--bg-white",
          { "is-selected": isSelected(client.id) },
          { "has-popup-open": showActions },
        ])}
      >
        <div className="c-card__body">
          <div className="c-card__content">
            <p className="c-card__title">
              <Link to={editClientLink}>{client.name}</Link>
            </p>
            <div className="c-card__info">
              {client.updated_at && (
                <p>
                  Updated: <strong>{getRelativeTimeString(client.updated_at)}</strong>
                </p>
              )}
            </div>
          </div>
          <div className="c-card__footer">
            <Link to={editClientLink} className="c-link-cta-light">
              <IconPen className="o-svg-icon" />
              <span>Edit</span>
            </Link>
            <div className="c-card__actions c-card__link-hidden">
              <div
                onClick={(event) => setShowActions(!showActions)}
                className="c-card__actions-toggle"
              >
                <IconMore className="o-svg-icon" />
              </div>
              <div className="c-popup">
                <div className="c-popup__header c-popup__header--sm">
                  <p className="c-popup__title">Actions</p>
                </div>
                <div className="c-popup__body">
                  <ul className="c-popup__list c-popup__list--small">
                    {/* <li>
                      <div className="c-popup__link" onClick={(event) => { event.preventDefault() }}>
                        <IconEye className="o-svg-icon" /><span>Log in as User</span>
                      </div>
                    </li> */}
                    <li>
                      <Link to={editClientLink}>
                        <IconPen className="o-svg-icon" />
                        <span>Edit Client</span>
                      </Link>
                    </li>
                    <li className="c-popup__divider c-popup__divider--top">
                      <div
                        className="c-popup__link"
                        onClick={(event) => {
                          event.preventDefault();
                          setIsDeleting(true);
                          setShowActions(false);
                        }}
                      >
                        <IconTrash className="o-svg-icon" />
                        <span>Delete Client</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className={classNames(
                "c-form-element",
                "c-form-element--style-fill",
                "c-form-element--checkbox",
                "c-card__checkbox",
                { "c-card__link-hidden": !isSelected(client.id) }
              )}
            >
              <div className="c-form-element__field">
                <input
                  checked={isSelected(client.id)}
                  type="checkbox"
                  id={`check-listing-item-${client.id}`}
                  onChange={(event) => {
                    selectHandler([client.id]);
                  }}
                />
                <label htmlFor={`check-listing-item-${client.id}`}></label>
              </div>
            </div>
          </div>
          <DeleteConfirmationAlert
            onDelete={() => {
              deleteHandler([client.id]);
              setIsDeleting(false);
            }}
            onCancel={() => {
              setIsDeleting(false);
            }}
            resource_label={client.name}
            show={isDeleting}
            type={DeleteConfirmationAlertType.Card}
          />
        </div>
      </div>
    </div>
  );
};

export default ClientCard;
