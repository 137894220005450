import api from "api";
import useRequest from "api/use-request";
import { defineAbilityFor, TAppAbilityCanParams } from "authorizations";
import GuardedRoute from "components/routes/guarded";
import { RouteProps } from "react-router";
import { Redirect } from "react-router-dom";

export interface AuthorizedRouteProps extends RouteProps {
  subject: TAppAbilityCanParams[1];
  action: TAppAbilityCanParams[0];
}

export function AuthorizedRoute({ subject, action, ...rest }: AuthorizedRouteProps) {
  const user = useRequest(api.users.getCurrentUser());
  const authorizedUser = defineAbilityFor(user.data);

  return (
    <GuardedRoute
      {...rest}
      guard={(params, children) => {
        if (authorizedUser.can(action, subject)) {
          window.loader(false);
          return children();
        } else {
          if (user.data) {
            return <Redirect to="/home" />;
          }
          window.loader(true);
          return <div></div>;
        }
      }}
    />
  );
}

export default AuthorizedRoute;
