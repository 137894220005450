import { ReactComponent as IconAdd } from "assets/svg/add.svg";
import * as React from "react";

import ClientsListing from "components/cards/clients-listing";

// Icons
import { PageContext } from "components/layout/page-context";
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

const ClientsPage: React.FunctionComponent = () => {
  const page = useContext(PageContext);

  useEffect(() => {
    page.setTitle("Manage Clients");
    page.setBackLink();
    page.setShowsClientSwitcher(false);
  });

  return (
    <section className="c-block c-block--spacing-b-small">
      <div className="o-container-fluid o-container--full">
        <div className="c-page-title">
          <h2 className="c-title--large">All clients</h2>
          <Link
            to="/client/new"
            className="c-button c-button--stroke c-button--secondary c-button--icon u-hidden@md"
          >
            <IconAdd className="o-svg-icon" />
          </Link>
          <Link
            to="/client/new"
            className="c-button c-button--stroke c-button--secondary u-hidden u-block@md"
          >
            <IconAdd className="o-svg-icon" />
            <span>Add new client</span>
          </Link>
        </div>
        <ClientsListing clientId={"-1"} />
      </div>
    </section>
  );
};

export default ClientsPage;
