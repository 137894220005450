import { ReactComponent as IconAdd } from "assets/svg/add.svg";
import * as React from "react";

// Icons
import { ReactComponent as IconAddItem } from "assets/svg/add-item.svg";

// Components
import ClientCard from "components/cards/client";
import Listing from "components/cards/listing";
import { listingContext, ListingSortOption, SortDirection } from "components/cards/listing-context";

import api from "api";
import { UserRoleTypes } from "api/endpoints/users";
import useRequest from "api/use-request";
import queryString from "query-string";
import { Suspense, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { usersRefresh } from "utils/users-refresh-singleton";

export interface IClientsListingProps {
  clientId: string;
}

const ClientsListing: React.FunctionComponent<IClientsListingProps> = ({ clientId }) => {
  let location = useLocation();
  let searchArgs = queryString.parse(location.search, { parseNumbers: true });
  if (searchArgs.client_id === undefined) {
    searchArgs.client_id = clientId;
  }
  if (searchArgs.limit === undefined) {
    searchArgs.limit = 12;
  }

  let [selection, setSelection] = useState<string[]>([]);

  const currentUser = useRequest(api.users.getCurrentUser());
  let clients = useRequest(api.clients.getClients(searchArgs));

  let sort_options: Array<ListingSortOption> = [
    { key: "name", title: "Name, Ascending", direction: SortDirection.Ascending },
    { key: "name", title: "Name, Descending", direction: SortDirection.Descending },
    { key: "created_at", title: "Created At, Ascending", direction: SortDirection.Ascending },
    { key: "created_at", title: "Created At, Descending", direction: SortDirection.Descending },
  ];

  function deleteClients(ids: string[]) {
    let updates: Promise<unknown>[] = [];
    ids.forEach((id) => {
      updates.push(api.clients.deleteClient({ id }).fetch());
    });
    Promise.all(updates).finally(() => {
      clients.mutate();
      setSelection([]);
    });
  }

  function toggleSelectClients(ids: string[]) {
    ids.forEach((id) => {
      const index = selection.indexOf(id);
      if (index > -1) {
        selection.splice(index, 1);
      } else {
        selection.push(id);
      }
    });
    setSelection([...selection]);
  }
  // eslint-disable-next-line
  React.useEffect(() => {
    if (usersRefresh.needsRefresh) {
      clients.mutate();
      usersRefresh.needsRefresh = false;
      setSelection([]);
    }
  });

  return (
    <>
      {clients.data && clients.data?.data.length < 1 ? (
        <NoClientsPlaceholder />
      ) : (
        <>
          <listingContext.Provider value={{ ...clients, selection, sort_options }}>
            <Suspense fallback={<div>Loading...</div>}>
              <Listing
                deleteHandler={undefined}
                selectionHandler={toggleSelectClients}
                selectAllHandler={undefined}
                settingsKey={"Clients"}
              >
                {clients.data?.data.map((client) => (
                  <ClientCard
                    deleteHandler={deleteClients}
                    selectHandler={toggleSelectClients}
                    key={client.id}
                    client={client}
                    shouldShowClient={currentUser.data?.role === UserRoleTypes.Admin}
                  />
                ))}
              </Listing>
            </Suspense>
          </listingContext.Provider>
        </>
      )}
    </>
  );
};

function NoClientsPlaceholder() {
  let history = useHistory();
  let location = useLocation();
  let newClientLink = location.pathname + "/new";

  return (
    <div className="u-mb-spacer-base-large">
      <div className="c-add c-add--link u-flex-nowrap@md">
        <div className="c-add__icon">
          <IconAddItem className="o-svg-icon" />
        </div>
        <div className="c-add__body">
          <div className="c-add__title">
            <div
              onClick={() => {
                history.push(newClientLink);
              }}
              className="c-link-cta c-add__link"
            >
              <IconAdd className="o-svg-icon" />
              <span>Add new client</span>
            </div>
          </div>
          <div className="c-add__desc">
            <p>You currently don’t have any client added, click here to start.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientsListing;
