import * as React from "react";
import { useContext } from "react";
import { PageContext } from "./page-context";

export interface IPageTitleProps {
  maximumCharacters?: number;
  containerRef?: any;
}

const PageTitle: React.FunctionComponent<IPageTitleProps> = (props) => {
  const page = useContext(PageContext);
  let title = page.title;

  if (props.maximumCharacters && title.length > props.maximumCharacters) {
    title = title.substr(0, props.maximumCharacters) + "...";
  }

  return <p>{title}</p>;
};

export default PageTitle;
