import classNames from "classnames";
import _ from "lodash";
import * as React from "react";
import { ReactChild } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

export interface ITabsPanelProps {
  children: ReactChild;
}

export interface TabSettings {
  label: string;
  path: string;
}

export interface ITabUrlParams {
  activeTab: string;
}

export interface ITabsNavProps {
  tabs: TabSettings[];
  children: ReactChild;
  replaceUrl?: boolean;
  compact?: boolean;
}

export function TabsNav({ tabs, children, replaceUrl, compact }: ITabsNavProps) {
  let location = useLocation();
  let history = useHistory();

  function isActive(index: number) {
    return _.findIndex(tabs, { path: location.pathname }) === index;
  }

  let handleTabChange = (event: React.FormEvent<HTMLSelectElement>) => {
    const element = event.target as HTMLSelectElement;
    let selectedTab = tabs[Number(element.value)];
    history.replace(selectedTab.path);
  };

  return (
    <div>
      <nav
        className={classNames([
          "c-tabs-nav",
          { "u-mb-spacer-section": !compact },
          { "u-mb-spacer-section-small": compact },
        ])}
      >
        <form className="c-tabs-nav-menu c-tabs-nav-menu--dropdown u-hidden@md">
          <div className="c-form-element c-form-element--style-fill c-form-element--select">
            <label htmlFor="form-12-tabs" className="c-form-label">
              Go to...
            </label>
            <div className="c-form-element__field">
              <select
                id="form-12-tabs"
                onChange={handleTabChange}
                value={_.findIndex(tabs, { path: location.pathname })}
              >
                {tabs.map((tab, index) => (
                  <option key={index} value={index}>
                    {tab.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </form>
        <ul className="c-tabs-nav-menu c-tabs-nav-menu--horizontal u-hidden u-flex@md">
          {tabs.map((tab, index) => (
            <li key={index} className={classNames([{ "is-selected": isActive(index) }])}>
              {replaceUrl ? (
                <Link
                  to={tab.path}
                  onClick={(event) => {
                    event.preventDefault();
                    history.replace(tab.path);
                  }}
                >
                  <span>{tab.label}</span>
                </Link>
              ) : (
                <Link to={tab.path}>
                  <span>{tab.label}</span>
                </Link>
              )}
            </li>
          ))}
        </ul>
      </nav>
      <div className="c-tabs-content">{children}</div>
    </div>
  );
}

export function TabsPanel({ children }: ITabsPanelProps) {
  return <div className={classNames(["c-tabs-item", "is-active"])}>{children}</div>;
}
