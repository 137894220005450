import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import config from "config";
import { useEffect } from "react";
import Routes from "routes";
import { useStore } from "store";

Sentry.init({
  dsn: config.SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: config.APP_ENVIRONMENT,
  release: config.APP_VERSION,
  tracesSampleRate: config.APP_ENVIRONMENT === "production" ? 0.05 : 0.25,
});

function App() {
  const store = useStore();
  useEffect(() => {
    window.loader(false);
    if (store.isLoggedIn) {
      document.body.classList.add("has-site-nav");
    }
  }, []);
  return <Routes />;
}

export default App;
