import DashboardsListing from "components/cards/dashboards-listing";
import * as React from "react";

interface IHomePageProps {}

const HomePage: React.FunctionComponent<IHomePageProps> = (props) => {
  return (
    <section className="c-block c-block--spacing-b-small">
      <div className="o-container-fluid o-container--full">
        <div className="c-page-title">
          <h2 className="c-title--large">My Dashboards</h2>
        </div>
        <DashboardsListing showAllDashboards={true} clientId={"-1"} />
      </div>
    </section>
  );
};

export default HomePage;
