import * as React from "react";

import ClientGeneralTab from "components/tabs/client-general";

const AddClientPage: React.FunctionComponent = () => {
  return (
    <section className="c-block c-block--spacing-b-small">
      <div className="o-container-fluid o-container--full">
        <div className="c-page-title">
          <h2 className="c-title--large">New Client</h2>
        </div>
        <ClientGeneralTab isNew={true} clientId={"0"} />
      </div>
    </section>
  );
};

export default AddClientPage;
