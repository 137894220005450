import api from "api";
import { ClientObject } from "api/endpoints/clients";
import { DashboardObject } from "api/endpoints/dashboards";
import { createContext, ReactElement, useContext, useEffect, useRef, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import DashboardPasswordForm from "./dashboard-password-form";
import { PageContext } from "./page-context";


export type IDashboardContext = {
  worker: Worker | undefined;
  setWorker: (worker: Worker) => void;
  root: boolean;
  setRoot: (root: boolean) => void;
  client: ClientObject | undefined;
  setClient: (client: ClientObject) => void;
  dashboard: DashboardObject | undefined;
  setDashboard: (dashboard: DashboardObject) => void;
  rate: IRates;
  setRate: (rate: IRates) => void;
  paused: boolean;
  setPaused: (paused: boolean) => void;
  status: string;
  setStatus: (root: string) => void;
  count: number;
  setCount: (count: number) => void;
  incrementCount: () => void;
};

export const DashboardContext = createContext({} as IDashboardContext);

export type IDashboardContextProvider = {
  children: ReactElement;
  isRoot?: boolean;
};

interface IRates {
  PercentageS: number;
  PercentageM: number;
  PercentageH: number;
  RateS: number;
  RateM: number;
  RateH: number;
  CurrentValue: number;
}
interface IRatesDashboard extends IRates {
  Paused: boolean;
  client: ClientObject | undefined;
  dashboard: DashboardObject | undefined;
}

enum PasswordStatus {
  unset = "unset",
  setting = "setting",
  set = "set",
  failed = "failed",
}

const dashboardPasswordKey = (clientSlug: string, dashboardSlug: string) => {
  return "dashboard-" + clientSlug + "-" + dashboardSlug + "-password";
};

const adjustRate = (count: number, rates: IRatesDashboard, diff: number): IRatesDashboard => {
  // Adjust the rate downward to slow down the counter for the next minute
  rates.RateS = ((rates.RateS - diff / 60) * 1e2) / 1e2;
  rates.RateM = Math.round(((rates.RateM - diff) * 1e2) / 1e2);
  rates.RateH = Math.round(((rates.RateH - diff * 60) * 1e2) / 1e2);
  // Adjust the percents downward to represent the rate
  const percentDiff = rates.dashboard?.local_max
    ? (diff / (parseFloat(rates.dashboard?.local_max) * 60)) * 100
    : (diff / 2500) * 100;
  rates.PercentageS = rates.PercentageS - percentDiff;
  rates.PercentageM = rates.PercentageM - percentDiff;
  rates.PercentageH = rates.PercentageH - percentDiff;
  rates.CurrentValue = count;
  return rates;
};

/**
 * Fetch the data for the dashboard from the API
 * @param root
 * @param clientSlug
 * @param dashboardSlug
 * @returns
 */
const fetchData = async (
  root: boolean,
  clientSlug: string,
  dashboardSlug: string
): Promise<IRatesDashboard> => {
  let connection;
  if (root) {
    connection = api.dashboards.rootData();
  } else {
    let dashboardPassword = localStorage.getItem(
      "dashboard-" + clientSlug + "-" + dashboardSlug + "-password"
    );
    connection = api.dashboards.widgetData(clientSlug, dashboardSlug, dashboardPassword);
  }
  const data = await connection.fetch();
  if (data?.dashboard?.is_paused) {
    return {
      PercentageS: 0,
      PercentageM: 0,
      PercentageH: 0,
      RateS: 0,
      RateM: 0,
      RateH: 0,
      CurrentValue: data?.CurrentValue ?? 0,
      Paused: data?.dashboard?.is_paused ?? false,
      client: data?.client,
      dashboard: data?.dashboard,
    };
  }
  return {
    PercentageS: data?.PercentageS ?? 0,
    PercentageM: data?.PercentageM ?? 0,
    PercentageH: data?.PercentageH ?? 0,
    RateS: data?.RateS ?? 0,
    RateM: data?.RateM ?? 0,
    RateH: data?.RateH ?? 0,
    CurrentValue: data?.CurrentValue ?? 0,
    Paused: data?.dashboard?.is_paused ?? false,
    client: data?.client,
    dashboard: data?.dashboard,
  };
};

/**
 * Given a count, rates, and setRate function, adjust the rate to match the desired count
 * @param count
 * @param rates
 * @param setRate
 */
const processRate = (count: number, rates: IRatesDashboard, setRate: (rate: IRates) => void) => {
  if (count) {
    // If the counter is above the new value, the rate slowed down
    console.log(
      `Adjusting rate | count: ${count} | current value: ${rates.CurrentValue} | diff: ${count - rates.CurrentValue
      } | rate: ${rates.RateM}`
    );
    if (count > rates.CurrentValue) {
      console.log(
        "Need to slow down - Offset is " +
        (count - rates.CurrentValue) +
        "| Past Rate is " +
        rates.RateS
      );
      // We need to keep counting, but slow the new rate down so that after 60 seconds it'll converge
      // with the new total
      let diff = count - rates.CurrentValue;

      if (rates.RateM - diff <= 0) {
        // Rate would drop below sane minimum threshold
        // Add some noise to make base rate look more interesting
        console.log(
          "Rate would drop below sane minimum threshold" + (rates.RateM - diff) + " " + diff
        );
        // Set the rate to 1 Unit per minute
        console.log("Using dashboard min rate", rates.dashboard?.local_min);
        if (rates.dashboard?.local_min) {
          const local_min = parseFloat(rates.dashboard?.local_min) * 60;
          // drop the rate 90% below the local min
          diff = rates.RateM - local_min * 0.9;
        } else {
          diff = rates.RateM - 0.1;
        }
        rates = adjustRate(count, rates, diff);
      } else {
        diff = diff / 3;
        rates = adjustRate(count, rates, diff);
      }

      console.log("Slowing rate down by " + diff / 60 + " | Estimated rate is " + rates.RateS);
    } else if (count < rates.CurrentValue) {
      // If the counter is below the new value, the rate speed up
      console.log(
        "Need to speed up | Offset is " +
        (count - rates.CurrentValue) +
        "| Past Rate is " +
        rates.RateS
      );
      let diff = (count - rates.CurrentValue) / 3; // this dampens the rate increase
      rates = adjustRate(count, rates, diff);
      console.log("Speeding rate up by " + diff / 60 + " | Estimated rate is " + rates.RateS);
    }
  } else {
    console.log(
      "User toggled back to screen, or is more than 5 minutes from last fetch.  Defaulting to api rate",
      count
    );
    // count = rates.CurrentValue;
  }

  // estimatedHistory.push({
  //   Value: String(rates.CurrentValue),
  //   RateS: String(rates.Rate.S),
  //   Timestamp: Math.floor(Date.now() / 1000 / 60) * 60,
  //   Delta: 60,
  // });

  // estimatedHistory.splice(0, estimatedHistory.length - 25);
  // window.localStorage.setItem("locusEstimateHistory", JSON.stringify(estimatedHistory));
  setRate(rates);
};

export const DashboardContextProvider = ({ children, isRoot }: IDashboardContextProvider) => {
  let [worker, setWorker] = useState<Worker | undefined>(undefined);
  let [root, setRoot] = useState<boolean>(isRoot ? true : false);
  let [status, setStatus] = useState("loading");
  let [count, setCount] = useState(0);
  let [paused, setPaused] = useState(false);
  let [rate, setRate] = useState({
    PercentageS: 0,
    PercentageM: 0,
    PercentageH: 0,
    RateS: 0,
    RateM: 0,
    RateH: 0,
    CurrentValue: 0,
  });
  let [client, setClient] = useState<ClientObject | undefined>(undefined);
  let [dashboard, setDashboard] = useState<DashboardObject | undefined>(undefined);
  let [accessStatusCode, setAccessStatusCode] = useState(200);
  let [shouldFetch, setShouldFetch] = useState(true);
  let [passwordSubmitted, setPasswordSubmitted] = useState<PasswordStatus>(PasswordStatus.unset);

  const countRef = useRef(count); // define mutable ref
  const incrementCount = () => {
    setCount(count++);
  };

  window.setRate = (rate: IRates) => {
    setRate(rate);
  };

  const initialContext = {
    worker,
    setWorker,
    root,
    setRoot,
    client,
    setClient,
    dashboard,
    setDashboard,
    rate,
    setRate,
    status,
    setStatus,
    paused,
    setPaused,
    count,
    setCount,
    incrementCount,
  };

  const page = useContext(PageContext);

  let { clientSlug, dashboardSlug } = useParams<{ clientSlug: string; dashboardSlug: string }>();
  useEffect(() => {
    countRef.current = count;
  });
  useEffect(() => {
    page.setTitle(client?.name ? `${client.name} Dashboard` : "Dashboard");
    document.body.classList.add("is-ticker");
    return () => {
      document.body.classList.remove("is-ticker");
    };
  }, [client]);
  useEffect(() => {
    if (shouldFetch) {
      setShouldFetch(false);
      fetchData(root, clientSlug, dashboardSlug)
        .then((data) => {
          if (data.client) {
            setClient(data.client);
          }
          if (data.dashboard) {
            setDashboard(data.dashboard);
          }
          setPaused(data.Paused);
          setRate(data);
          countRef.current = data.CurrentValue;
          setCount(data.CurrentValue);
          setAccessStatusCode(200);
          setStatus("ready");
        })
        .catch((error) => {
          setStatus("loading");
          setAccessStatusCode(error.response.data.code);
        });
    }
  }, [shouldFetch]);

  // Bind the interval fetch only once the initial load is complete
  useEffect(() => {
    let frequency = 60000;
    if (status === "network-error") {
      frequency = 10000;
    }
    if (status === "ready" || status === "network-error") {
      const visibilityChangeHandler = (e: Event) => {
        console.log("Visibility has been restored", window.document.hidden);
        setStatus("refreshing");
        setShouldFetch(false);
        setShouldFetch(true);
      };
      window.addEventListener("visibilitychange", visibilityChangeHandler);

      const fetchInterval = setInterval(() => {
        fetchData(root, clientSlug, dashboardSlug)
          .then((data) => {
            if (status === "network-error") {
              setStatus("ready");
            }
            setPaused(data.Paused);
            return processRate(countRef.current, data, setRate);
          })
          .catch((error) => {
            setStatus("network-error");
          });
      }, frequency);
      return () => {
        clearInterval(fetchInterval);
        window.removeEventListener("visibilitychange", visibilityChangeHandler);
      };
    }
  }, [status]);

  useEffect(() => {
    if (status === "ready") {
      let currentRate = rate.RateS;
      if (paused) {
        currentRate = 0;
      }
      if (currentRate > 0) {
        if (typeof Worker !== "undefined") {
          console.log("Using web worker");
          const worker = new Worker("/count-worker.js?version=0.0.1");

          setWorker(worker);
          worker.postMessage(currentRate);

          worker.onmessage = function (e) {
            incrementCount();
          };
          return () => {
            worker.terminate();
            setWorker(undefined);
          };
        } else {
          let countInterval = setInterval(() => {
            incrementCount();
          }, 1000 / currentRate);

          return () => clearInterval(countInterval);
        }
      }
    }
  }, [status, rate.RateS]);

  switch (accessStatusCode) {
    case 401:
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: { pathname: window.location.pathname } },
          }}
        />
      );
    case 403:
      if (passwordSubmitted === PasswordStatus.setting) {
        setPasswordSubmitted(PasswordStatus.failed);
      }
      return (
        <DashboardPasswordForm
          submitHandler={(password: string) => {
            setPasswordSubmitted(PasswordStatus.setting);
            localStorage.setItem(dashboardPasswordKey(clientSlug, dashboardSlug), password);
            setShouldFetch(true);
          }}
          error={passwordSubmitted === PasswordStatus.failed ? "Incorrect password." : undefined}
        />
      );
    case 404:
      return <Redirect to="/404" />;
    default:
      if (passwordSubmitted === PasswordStatus.setting) {
        setPasswordSubmitted(PasswordStatus.set);
      }
      return (
        <DashboardContext.Provider value={initialContext}>{children}</DashboardContext.Provider>
      );
  }
};
