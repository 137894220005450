import { GetListingRequestSchema, LinksSchema, MetaSchema } from "api/endpoints/common";
import HttpClient from "api/http-client";
import { dateRegex } from "api/util";
import * as z from "zod";
import { ClientObjectSchema } from "../clients";

export enum UserRoleTypes {
  Admin = "admin",
  User = "user",
}

export type UserRole = { role: UserRoleTypes; title: string; priority: number };

export const UserRoles: UserRole[] = [
  { role: UserRoleTypes.Admin, title: "Admin", priority: 100 },
  { role: UserRoleTypes.User, title: "User", priority: 1 },
];

export const UserObjectSchema = z.object({
  id: z.string(),
  first_name: z.string().nonempty(),
  last_name: z.string().nonempty(),
  created_at: z.string().regex(dateRegex).optional(),
  updated_at: z.string().regex(dateRegex).optional(),
  last_seen: z.string().regex(dateRegex).optional(),
  email: z.string().email(),
  role: z.string().nonempty(),
  email_verified_at: z.string().regex(dateRegex).optional(),
  clients: z.array(ClientObjectSchema),
});

export const UserFormSchema = z.object({
  id: z.string(),
  first_name: z.string().nonempty(),
  last_name: z.string().nonempty(),
  email: z.string().email(),
  role: z.string().nonempty(),
  clients: z.array(z.string()),
});

export const CreateUserResponseSchema = UserObjectSchema;

export const GetUsersResponseSchema = z.object({
  data: z.array(UserObjectSchema),
  links: LinksSchema,
  meta: MetaSchema,
});

export const GetUsersRequestSchema = GetListingRequestSchema;

export const UserObjectIdSchema = z.object({
  id: z.string(),
});

export const SendWelcomeEmailRequestObjectSchema = z.object({
  user_id: z.string(),
  return_url: z.string(),
});

export type UserObject = z.infer<typeof UserObjectSchema>;
export type UserFormObject = z.infer<typeof UserFormSchema>;
export type GetUsersRequest = z.infer<typeof GetUsersRequestSchema>;
export type GetUsersResponse = z.infer<typeof GetUsersResponseSchema>;
export type UserObjectId = z.infer<typeof UserObjectIdSchema>;
export type SendWelcomeEmailRequestObject = z.infer<typeof SendWelcomeEmailRequestObjectSchema>;

export function getUsers(data: GetUsersRequest) {
  return new HttpClient().request<GetUsersResponse>({
    method: "get",
    url: "/users",
    validator: GetUsersResponseSchema,
    params: data,
  });
}

export function newUser(data: Omit<UserFormObject, "id">) {
  return new HttpClient().request<UserObject>({
    method: "post",
    url: "/user",
    validator: UserObjectSchema,
    data,
  });
}

export function getUser(data: UserObjectId) {
  return new HttpClient().request<UserObject>({
    method: "get",
    url: `/user/${data.id}`,
    validator: UserObjectSchema,
  });
}

export function deleteUser(data: UserObjectId) {
  return new HttpClient().request({
    method: "delete",
    url: `/user/${data.id}`,
    data,
  });
}

export function updateUser(data: UserFormObject, user_id: string) {
  return new HttpClient().request<UserObject>({
    method: "put",
    url: `/user/${user_id}`,
    data,
  });
}

export function getCurrentUser() {
  return new HttpClient().request<UserObject>({
    method: "get",
    url: `/me`,
  });
}

export function sendWelcomeEmail(data: SendWelcomeEmailRequestObject) {
  return new HttpClient().request({
    method: "post",
    url: "/user/send-welcome-email",
    data,
  });
}

export function roleForUser(user: UserObject): UserRole {
  let matchedRole = UserRoles[UserRoles.length - 1];
  UserRoles.forEach((role) => {
    if (role.role === user.role) matchedRole = role;
  });
  return matchedRole;
}

export function allowedRolesForUser(user: UserObject, userBeingEdited: UserObject): UserRole[] {
  let allowedRoles: UserRole[] = [];
  let editorRole = roleForUser(user);
  let editedRole = roleForUser(userBeingEdited);

  UserRoles.forEach((role) => {
    if (
      (editedRole.priority <= editorRole.priority && editorRole.priority > role.priority) ||
      editorRole.role === role.role
    ) {
      allowedRoles.push(role);
    }
  });
  return allowedRoles;
}
