import { Redirect, Route, Switch } from "react-router-dom";

import BasicLayout from "layouts/basic";
import DashboardLayout from "layouts/dashboard";
import MainLayout from "layouts/main";

import { useStore } from "store";

import AccessDeniedPage from "pages/access-denied";
import LoginPage from "pages/login";
import NotFoundPage from "pages/not-found";
import ForgotPasswordPage from "pages/password-forgot";
import ResetPasswordPage from "pages/password-reset";
import ProfilePage from "pages/profile";
import UsersPage from "pages/users";
import VerifyEmailPage from "pages/verify-email";

// import HomePage from 'pages/home';

import api from "api";
import useRequest from "api/use-request";
import { AuthorizationContext, defineAbilityFor } from "authorizations";
import AuthRoute from "components/routes/auth";
import { AuthenticatedRoute } from "components/routes/authenticated";
import AuthorizedRoute from "components/routes/authorized";
import ClientPage from "pages/client";
import AddClientPage from "pages/client-add";
import ClientsPage from "pages/clients";
import DashboardPage from "pages/dashboard";
import DashboardsPage from "pages/dashboards";
import HomePage from "pages/home";

export interface LocationState {
  from?: {
    pathname: string;
  };
}

export default function Routes() {
  const store = useStore();
  const user = useRequest(api.users.getCurrentUser(), store.isLoggedIn);

  if (user.data) {
    store.currentUser(user.data);
    store.setUserRole(api.users.roleForUser(user.data).role);
  }

  let authorizations = defineAbilityFor(store.isLoggedIn ? user.data : undefined);

  const home = store.isSuperAdmin ? "/clients" : "/home";

  return (
    <AuthorizationContext.Provider value={authorizations}>
      <Switch>
        <Route exact path="/">
          {store.isLoggedIn ? <Redirect to={home} /> : <Redirect to="/login" />}
        </Route>

        <AuthRoute exact path="/login">
          <BasicLayout>
            <LoginPage />
          </BasicLayout>
        </AuthRoute>

        <Route exact path="/password/forgot">
          <BasicLayout>
            <ForgotPasswordPage />
          </BasicLayout>
        </Route>

        <Route exact path="/password/reset">
          <BasicLayout>
            <ResetPasswordPage />
          </BasicLayout>
        </Route>

        <Route exact path="/verify-email">
          <BasicLayout>
            <VerifyEmailPage />
          </BasicLayout>
        </Route>

        <Route exact path="/404">
          <BasicLayout>
            <NotFoundPage />
          </BasicLayout>
        </Route>

        <AuthenticatedRoute path="/home">
          {store.isSuperAdmin ? (
            <Redirect to="/clients" />
          ) : (
            <MainLayout>
              <HomePage />
            </MainLayout>
          )}
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/clients">
          <AuthorizedRoute exact path="/clients" subject="Client" action="list">
            <MainLayout>
              <ClientsPage />
            </MainLayout>
          </AuthorizedRoute>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/users">
          <AuthorizedRoute exact path="/users" subject="User" action="list">
            <MainLayout>
              <UsersPage />
            </MainLayout>
          </AuthorizedRoute>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/client/new">
          <AuthorizedRoute path="/client/new" subject="Client" action="create">
            <MainLayout>
              <AddClientPage />
            </MainLayout>
          </AuthorizedRoute>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/client/:clientId">
          <AuthorizedRoute path="/client/:clientId" subject="Client" action="read">
            <MainLayout>
              <ClientPage />
            </MainLayout>
          </AuthorizedRoute>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/dashboards">
          <AuthorizedRoute path="/dashboards" subject="Dashboard" action="list">
            <MainLayout>
              <DashboardsPage />
            </MainLayout>
          </AuthorizedRoute>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/profile">
          <AuthorizedRoute path="/profile" subject="User" action="update">
            <MainLayout>
              <ProfilePage returnTo="" />
            </MainLayout>
          </AuthorizedRoute>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/401">
          <Route exact path="/401">
            <MainLayout>
              <AccessDeniedPage />
            </MainLayout>
          </Route>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/403">
          <Route exact path="/403">
            <MainLayout>
              <AccessDeniedPage />
            </MainLayout>
          </Route>
        </AuthenticatedRoute>

        <Route path="/:clientSlug/:dashboardSlug">
          <DashboardLayout>
            <DashboardPage root={false} />
          </DashboardLayout>
        </Route>

        <Route path="*">
          <MainLayout>
            <NotFoundPage />
          </MainLayout>
        </Route>
      </Switch>
    </AuthorizationContext.Provider>
  );
}
