import { DashboardContext } from "components/layout/dashboard-context";
import React from "react";

export function Count() {
  let context = React.useContext(DashboardContext);
  const formatter: Intl.NumberFormat = new Intl.NumberFormat("en-US");
  return (
    <>
      <div className="count">{formatter.format(context.count)}</div>
    </>
  );
}
