import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import config from "config";
import { store } from "store";
import debug from "utils/debug";
import { phpToDate, phpToUndefined } from "utils/types";
import * as z from "zod";

export interface HttpRequestConfig extends AxiosRequestConfig {
  validator?: z.ZodSchema<unknown>;
}
export interface HttpResponse<T = any> extends AxiosResponse<T> {}
export interface HttpError<T = any> extends AxiosError<T> {}

export default class HttpClient<T> {
  private static _instance: AxiosInstance;
  config?: HttpRequestConfig;
  fetch: () => Promise<T>;

  /**
   * Http layer suitable for useSWR
   * @param request axios compatible configuration object
   */
  constructor() {
    if (!HttpClient._instance) {
      HttpClient._instance = axios.create({
        baseURL: config.API_BASE,
        headers: {
          common: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        },
        responseType: "json",
      });
    }

    this.fetch = () => {
      throw new Error("Cannot fetch an unconfigured request.");
    };
  }

  request<T>(request: HttpRequestConfig): HttpClient<T> {
    const recast = new HttpClient<T>();
    recast.config = request;
    recast.fetch = async () => {
      try {
        let response: HttpResponse<T> = await this.getInstance().request<T>(request);
        store.errors.clearNetworkError();

        response = phpToUndefined(response);
        response = phpToDate(response);

        if (request.validator) {
          request.validator.parse(response.data);
        }
        return response.data;
      } catch (error: any) {
        debug(error);
        if (!error.response && error.request) {
          store.errors.presentNetworkError(
            "Application is having difficulties connecting to the server, please make sure your internet connection is on and working."
          );
        }
        throw error;
      }
    };

    return recast;
  }
  /**
   * Set the bearer token for authentication
   * @param token string
   */
  public setBearer(token: string | null): void {
    if (token) {
      HttpClient._instance.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
      this.removeBearer();
    }
  }

  /**
   * Remove the bearer token for authentication
   * @param token string
   */
  public removeBearer(): void {
    delete HttpClient._instance.defaults.headers.common.Authorization;
  }

  /**
   * Accessor for the static instance
   */
  public getInstance() {
    return HttpClient._instance;
  }
}
